// Dashboard.js
import React from 'react';
import Sidebar from '../components/sidebar';

export default function Dashboard() {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        {/* Dashboard content goes here */}
        <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
        <p> Dashboard content goes here...</p>
      </div>
    </div>
  );
}
