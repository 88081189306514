import React from 'react';
import Sidebar from '../components/sidebar';
import { rightTickIcon } from '../Icons';
import { Link } from 'react-router-dom';

// MembershipPlan component
const MembershipPlan = ({ name, price, features, color, textColor, customHeight, buttonText }) => (
  <div className={`flex flex-col max-w-lg p-6 text-center rounded-lg ${color} ${textColor} ${customHeight} border border-blue-500  xl:p-8`}>
    <h3 className="mb-4 text-2xl font-semibold">{name}</h3>
    <div className="flex items-baseline justify-center my-8">
      <span className="mr-2 text-5xl font-extrabold">{price}</span>
      <span className="text-gray-500 dark:text-gray-400">/month</span>
    </div>

    <ul className="mb-8 space-y-2 text-sm text-left">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center space-x-3">
          {rightTickIcon}
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <Link to="/" className={`text-white hover:text-white ${color === 'bg-purple-600' ? 'bg-white text-purple-600' : 'bg-purple-600'} border-b hover:bg-purple-700 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center `}>
      {buttonText}
    </Link>
  </div>
);

// Membership component
const Membership = () => {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 overflow-auto">
        <section className="">
          <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div className="max-w-screen-md mx-auto mb-8 lg:mb-12">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight">Choose Your Plan</h2>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 lg:space-y-0 ">
              {/* Starter Plan */}
              <MembershipPlan
    name="Guard Basic"
    price="€150"
    features={['1 user 1 connected calendar', 'Google Workspace,Exchange?', 'Upto 12 responses', 'Upto 3 survey results achieved', '7 Days of Insight report storage of past meetings', 'Knowledge base']}
    color="bg-white"
    textColor="text-black"
    buttonText="Get started"
    customHeight="h-[560px] "
  />

              {/* Company Plan */}
              <MembershipPlan
    name="Secure Plus"
    price="€250"
    features={['1 user up to 2 connected calendars', 'Google Workspace,Exchange?', 'Upto 50 responses', 'Upto 10 surveys results achieved', '30 Days of Insight report storage of past meetings', 'Unlimited customization of questions', 'Upto 5 surveys templates', 'Standard email & chat ']}
    color="bg-purple-600"
    textColor="text-white"
    buttonText="Get started"
    customHeight="h-[600px] shadow-2xl shadow-blue-300" 
  />

              {/* Cyber Premium */}
              <MembershipPlan
    name="Cyber Premium"
    price="€500"
    features={['Per user in company domain with single calendar integrations', 'Google Workspace,Exhange?', 'Unlimited responses', 'Unlimited survey results achieved', 'Unlimited Insight report storage of past meetings', 'Unlimited customization of questions', 'Resend your survey', 'Priority email & chat support']}
    color="bg-white"
    textColor="text-black"
    buttonText="Get started"
    customHeight="h-[560px] "
  />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Membership;
