import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../contexts/FormContext';
import SERVER_URL from '../components/utils';
import { Link, useNavigate } from 'react-router-dom';

export default function PolicyTemp() {
    const { formData } = useContext(FormContext);
    const navigate = useNavigate();

    const authToken = localStorage.getItem('token');
    const [formFields, setFormFields] = useState([]);
    const [data, setData] = useState('');
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [errors, setErrors] = useState({});



    useEffect(() => {
        if (formData && formData.data) {
            const highlightedContent = highlightTagNames(formData.data);
            setData(highlightedContent);
            const extractedFields = extractFieldNames(formData.data);
            // setFormFields(extractedFields.map(tagName => ({ tagName, dataType: 'String', required: false, question: '', preDefined: null })));
            const preFilledFields = extractedFields.map(tagName => {
                const existingField = formData.questionnaire?.find(q => q.tagName === tagName);
                return {
                    tagName,
                    dataType: existingField ? existingField.dataType : 'String',
                    required: existingField ? existingField.required : false,
                    question: existingField ? existingField.question : '',
                    preDefined: existingField ? existingField.preDefined : null,
                    showPredefinedInput: existingField ? existingField.showPredefinedInput : false,
                };
            });

            setFormFields(preFilledFields);
        
        }
    }, [formData]);

    const extractFieldNames = (input) => {
        const matches = input.match(/\{"[a-zA-Z]+"\}/g);
        if (matches) {
            const uniqueMatches = [...new Set(matches)];
            return uniqueMatches.map(field => field.replace(/[\{\}"]/g, ''));
        }
        return [];
    };

    const highlightTagNames = (input) => {
        return input.replace(/\{"([a-zA-Z]+)"\}/g, (match, p1) => `<span style="color: blue;">${match}</span>`);
    };

    const handleInputChange = (index, key, value) => {
        const newFormFields = [...formFields];
        newFormFields[index][key] = value;
        setFormFields(newFormFields);

        if(key==='question'  && value.trim() === ''){
            setErrors(prevErrors => ({ ...prevErrors, [index]: 'This field is required' }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[index];
                return newErrors;
            });
        }
        
    };

  
    const getFormattedContent = async () => {
        const newErrors = {};
        formFields.forEach((field, index) => {
            if (!field.question.trim()) {
                newErrors[index] = 'This field is required';
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return; 
        }

       
        try {
            const rawContent = formData.data; // Use the original data without nested tags
            const highlightedContent = highlightTagNames(rawContent); // Highlight tags only once

            const response = await fetch(`${SERVER_URL}/api/createTemplate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    templateId: formData.id,
                    templateName: formData.name,
                    category: formData.category,
                    data: highlightedContent,
                    questionnaire: formFields,
                }),
            });
            if (response.ok) {
                console.log('Data saved successfully');
                setSubmissionSuccess(true);
                setTimeout(() => {
                    setSubmissionSuccess(false);
                    navigate('/policies?success=true');
                }, 2000);
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Server error:', error);
        }
    };

    return (
        <div>
             
        <div className="container mx-auto p-4">
            
            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-4">{formData.name}</h2>

                <h2 className="text-2xl font-bold mb-4">Dynamic Form</h2>
                {formFields.map((field, index) => (
                    <div key={index} className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2">
                            {field.tagName.charAt(0).toUpperCase() + field.tagName.slice(1)}:
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            tagName={field.tagName}
                            required={field.required}
                            value={field.question || ''}
                            // error={errors[index]}
                            onChange={(e) => handleInputChange(index, 'question', e.target.value)}
                        />
                        {errors[index] && <p className="text-red-500 text-xs italic">{errors[index]}</p>}
                       
                        <div className="mt-2">
                            <label className="block text-gray-700 mb-1">Input Type:</label>
                            <select
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => handleInputChange(index, 'dataType', e.target.value)}
                                value={field.dataType}
                            >
                                <option value="String">String</option>
                                <option value="Number">Number</option>
                                <option value="Boolean">Boolean</option>
                                <option value="Date">Date</option>
                            </select>
                        </div>
                      
                      <div className="mt-2 flex items-center space-x-2">
    <label className="text-gray-700">Predefined Values:</label>
    <input
        type="checkbox"
        className="ml-2 mt-1 leading-tight"
        checked={field.showPredefinedInput}
        onChange={(e) => handleInputChange(index, 'showPredefinedInput', e.target.checked)}
    />
</div>
{field.showPredefinedInput && (
    <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
        type="text"
        value={field.preDefined || ''}
        onChange={(e) => handleInputChange(index, 'preDefined', e.target.value)}
    />
)}

                        <div className="mt-2 flex items-center space-x-2">
                            <label className="block text-gray-700 mb-1">Required:</label>
                            <input
                                type="checkbox"
                                className="mr-2 leading-tight"
                                checked={field.required}
                                onChange={(e) => handleInputChange(index, 'required', e.target.checked)}
                            />
                        </div>
                    </div>
                ))}

                <div className="flex justify-between items-center p-4">
                    <Link to={'/form/' + formData.name}>
                        <button type="button" className="bg-[rgba(142,75,221,255)] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Back
                        </button>
                    </Link>

                    <button type="button" onClick={getFormattedContent} className="bg-[rgba(142,75,221,255)] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Save
                    </button>
                </div>

                {submissionSuccess && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded">
                    Form submitted successfully!
                </div>
            )}
               
            </div>
        </div>
        </div>
    );
}

