import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar';
import SERVER_URL from '../components/utils';
import { useParams } from 'react-router-dom';
import { EyeIcon, binIcon, pencilIcon, clockIcon, expandIcon, collapseIcon, expandQuestionIcon, collapseQuestionIcon } from '../Icons';
import Button from '../components/button';

export default function CreateCourses() {
  const { courseId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const authToken = localStorage.getItem('token');
  const [formData, setFormData] = useState({ courseName: '', courseDescription: '', courseCategory: '', chapters: [] });

  const getCourseById = async (courseId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getCourseById/${courseId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        console.error('Failed to fetch course');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const courseFormData = await getCourseById(courseId);
      if (courseFormData) {
        setFormData({
          courseName: courseFormData.courseName || '',
          courseDescription: courseFormData.courseDescription || '',
          chapters: courseFormData.chapters || []
        });
      } else {
        setFormData((prev) => ({ ...prev, chapters: [] }));
      }
      setIsLoading(false);
    };

    fetchData();
  }, [courseId]);

  const [collapsedChapters, setCollapsedChapters] = useState([]);
  const [collapsedQuestions, setCollapsedQuestions] = useState([]);

  const handleChange = (e, chapterIndex, questionIndex, optionIndex) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };

      if (chapterIndex !== undefined) {
        if (questionIndex !== undefined) {
          if (optionIndex !== undefined) {
            newFormData.chapters[chapterIndex].questionnaires[questionIndex].options[optionIndex] = {
              ...newFormData.chapters[chapterIndex].questionnaires[questionIndex].options[optionIndex],
              [name]: type === 'checkbox' ? checked : value,
            };
          } else {
            newFormData.chapters[chapterIndex].questionnaires[questionIndex] = {
              ...newFormData.chapters[chapterIndex].questionnaires[questionIndex],
              [name]: value,
            };
          }
        } else {
          newFormData.chapters[chapterIndex] = {
            ...newFormData.chapters[chapterIndex],
            [name]: value,
          };
        }
      } else {
        newFormData[name] = value;
      }

      return newFormData;
    });
  };

  const addChapter = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      chapters: [
        ...prevFormData.chapters,
        {
          chapterName: '',
          chapterDuration: '',
          questionnaires: [
            {
              question: '',
              searchQuery: '',
              options: [
                { optionText: '', isCorrect: false },
                { optionText: '', isCorrect: false },
                { optionText: '', isCorrect: false },
                { optionText: '', isCorrect: false },
              ],
            },
          ],
        },
      ],
    }));
  };

  const addQuestion = (chapterIndex) => {
    setFormData((prevFormData) => {
      const newChapters = prevFormData.chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            questionnaires: [
              ...chapter.questionnaires,
              {
                question: '',
                searchQuery: '',
                options: [
                  { optionText: '', isCorrect: false },
                  { optionText: '', isCorrect: false },
                  { optionText: '', isCorrect: false },
                  { optionText: '', isCorrect: false },
                ],
              },
            ],
          };
        }
        return chapter;
      });

      return { ...prevFormData, chapters: newChapters };
    });

    setCollapsedQuestions((prevCollapsedQuestions) => {
      const newCollapsedQuestions = [...prevCollapsedQuestions];
      if (!newCollapsedQuestions.includes(chapterIndex)) {
        newCollapsedQuestions.push(chapterIndex);
      }
      return newCollapsedQuestions;
    });
  };

  const toggleChapterCollapse = (chapterIndex) => {
    setCollapsedChapters((prevCollapsedChapters) => {
      if (prevCollapsedChapters.includes(chapterIndex)) {
        return prevCollapsedChapters.filter((i) => i !== chapterIndex);
      } else {
        return [...prevCollapsedChapters, chapterIndex];
      }
    });
  };

  const toggleQuestionCollapse = (chapterIndex, questionIndex) => {
    setCollapsedQuestions((prevCollapsedQuestions) => {
      if (prevCollapsedQuestions.includes(`${chapterIndex}-${questionIndex}`)) {
        return prevCollapsedQuestions.filter((i) => i !== `${chapterIndex}-${questionIndex}`);
      } else {
        return [...prevCollapsedQuestions, `${chapterIndex}-${questionIndex}`];
      }
    });
  };

  const calculateCourseDuration = () => {
    return formData.chapters.reduce((total, chapter) => {
      const timeInMinutes = parseInt(chapter.chapterDuration, 10) || 0;
      return total + timeInMinutes;
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Transform formData to the desired format
    const transformedData = {
      chapters: formData.chapters.map((chapter) => ({
        chapterName: chapter.chapterName,
        chapterDuration: Number(chapter.chapterDuration),
        questionnaires: chapter.questionnaires.map((question) => {
          return {
            searchQuery: question.searchQuery,
            question: question.question,
            options: question.options.map(option => ({
              optionText: option.optionText,
              isCorrect: option.isCorrect
            })),
          };
        })
      }))
    };

    try {
      const response = await fetch(`${SERVER_URL}/api/updateCourse/${courseId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(transformedData),
      });

      if (response.ok) {
        const updatedCourse = await response.json();
        console.log('Course updated successfully:', updatedCourse);
        setSuccessMessage('Course updated successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error('Failed to update course');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };


  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 mt-14 md:mx-2 flex flex-col h-full">
        <div className="flex-grow overflow-auto">
          <div className=" mx-auto">
            <form className="bg-white p-4" onSubmit={handleSubmit}>
              <div className="p-4">
                <h1 className="text-3xl mb-2">Course Name: {formData.courseName}</h1>
                <div className="mb-2 mt-2">
                  <h3 className="text-xl inline">Course Description: </h3>
                  <span className="inline">{formData.courseDescription}</span>
                </div>
              </div>
              {successMessage && (
                <div className="fixed top-20 z-10 p-8 w-full bg-green-500 text-white px-4 py-2 rounded mb-4">
                  {successMessage}
                </div>
              )}

              <div className="flex mb-4 mt-4">
                <div className="relative w-1/2 pr-2">
                  <label htmlFor="duration" className="text-gray-700 text-sm">Course Duration:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                    id="duration"
                    type="text"
                    placeholder="Duration"
                    name="duration"
                    value={calculateCourseDuration() + " minutes"}
                    disabled
                  />
                </div>

                <div className="relative w-1/2 pl-2">
                  <label htmlFor="chapters" className="text-gray-700 text-sm">Total Chapters:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                    id="chapters"
                    type="text"
                    placeholder="Chapters"
                    name="chapters"
                    disabled
                    value={formData.chapters.length}
                  />
                </div>
              </div>

              {formData.chapters.map((chapter, chapterIndex) => (
                <div key={chapterIndex} className="mb-4 border border-gray-300 p-4 rounded-lg">
                  <div className="flex pb-4">
                    <div className="relative w-9/12 pr-2">
                      <input
                        className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                        id={`chapterName${chapterIndex}`}
                        type="text"
                        placeholder={`Chapter ${chapterIndex + 1}`}
                        name="chapterName"
                        value={chapter.chapterName}
                        onChange={(e) => handleChange(e, chapterIndex)}
                      />
                    </div>

                    <div className="relative w-3/12 pl-2">
                      <input
                        className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                        id={`chapterDuration${chapterIndex}`}
                        type="text"
                        placeholder=""
                        name="chapterDuration"
                        value={chapter.chapterDuration}
                        onChange={(e) => handleChange(e, chapterIndex)}
                      />
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-slate-500">
                        {clockIcon}
                      </span>
                    </div>
                    <span
                      onClick={() => toggleChapterCollapse(chapterIndex)}
                      className="text-purple-700 cursor-pointer flex items-center ml-2"
                    >
                      {collapsedChapters.includes(chapterIndex) ? expandIcon : collapseIcon}
                    </span>
                  </div>

                  {!collapsedChapters.includes(chapterIndex) && (
                    <div>
                      {chapter.questionnaires.map((question, questionIndex) => (
                        <div key={questionIndex} className="pl-8 pr-8 mb-4">
                          <div className='flex pb-2'>
                            <div className="relative w-full group">
                              <input
                                className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                                id={`question${chapterIndex}${questionIndex}`}
                                type="text"
                                placeholder={`Question ${questionIndex + 1}`}
                                name="question"
                                value={question.question}
                                onChange={(e) => handleChange(e, chapterIndex, questionIndex)}
                              />
                              <span className="absolute right-7 top-1/2 transform -translate-y-1/2 cursor-pointer inline-flex text-slate-500 opacity-0 group-hover:opacity-100">
                                {binIcon}
                              </span>
                            </div>
                            <span
                              onClick={() => toggleQuestionCollapse(chapterIndex, questionIndex)}
                              className="text-purple-700 cursor-pointer flex items-center ml-2"
                            >
                              {collapsedQuestions.includes(`${chapterIndex}-${questionIndex}`) ? expandQuestionIcon : collapseQuestionIcon}
                            </span>
                          </div>
                          <div className='flex pb-2'>
                            <div className="relative mr-8 ml-9 w-full group">
                              <input
                                className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm"
                                id={`searchQuery${chapterIndex}${questionIndex}`}
                                type="text"
                                placeholder= "Search Query"
                                name="searchQuery"
                                value={question.searchQuery}
                                onChange={(e) => handleChange(e, chapterIndex, questionIndex)}
                              />
                            </div>
                          </div>

                          {collapsedQuestions.includes(`${chapterIndex}-${questionIndex}`) ? null : (
                            <div>
                              {question.options.map((option, optionIndex) => (
                                <div key={optionIndex} className="relative flex mb-4 group">
                                  {console.log(option)}
                                  <input
                                    type="checkbox"
                                    className="ml-2 mt-1 p-2 leading-tight flex-none"
                                    name="isCorrect"
                                    checked={option.isCorrect}
                                    onChange={(e) => handleChange(e, chapterIndex, questionIndex, optionIndex)}
                                  />
                                  <input
                                    className="shadow appearance-none border rounded w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:shadow-outline text-sm ml-4"
                                    id={`option${chapterIndex}${questionIndex}${optionIndex}`}
                                    type="text"
                                    placeholder="Option"
                                    name="optionText"
                                    value={option.optionText}
                                    onChange={(e) => handleChange(e, chapterIndex, questionIndex, optionIndex)}
                                  />
                                  <span className="absolute right-7 top-1/2 transform -translate-y-1/2 cursor-pointer inline-flex text-slate-500 opacity-0 group-hover:opacity-100">
                                    {binIcon}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                      <div className="flex justify-end">
                        <Button
                          onClick={() => addQuestion(chapterIndex)}
                          type="button"
                          className="bg-white text-xs text-purple-700 border-2 m-1 py-1 px-3.5 rounded-lg"
                        >
                          Add Question +
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              <div className="flex justify-center mb-16 space-x-4">
                <Button
                  onClick={addChapter}
                  type="button"
                  className="bg-white text-xs text-purple-700 border-2 py-1 px-3.5 rounded-lg"
                >
                  Add Chapter +
                </Button>

                <Button
                  type="submit"
                  className="bg-white text-xs text-purple-700 border-2 py-1 px-3.5 rounded-lg"
                >
                  Save Course
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
