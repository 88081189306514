import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner'; 
import SERVER_URL from './utils';

function PrivateRoutes() {
  const [isVerified, setIsVerified] = useState(null);
  const [showTokenExpiredMessage, setShowTokenExpiredMessage] = useState(false);
  const isAuthenticated = Cookies.get('x-auth-cookie') !== undefined || localStorage.getItem('token') !== null;
  const authToken = Cookies.get('x-auth-cookie') ?? localStorage.getItem('token');
  const navigate = useNavigate();

  function logout ()  {
    try{
      localStorage.removeItem('token');
      localStorage.clear();
      Cookies.remove('x-auth-cookie');
      navigate('/login');
    }
     catch (error) {
      console.error('Logout error:', error.message);
  
  };
  }

  useEffect(() => {
    const verifyToken = async () => {
      if (authToken) {
        try {
          const response = await axios.get(`${SERVER_URL}/api/auth/verifytoken`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          });
          setIsVerified(response.data.verified);
        } catch (error) {
          console.log('Error verifying token:', error);
          if (error.response && error.response.status === 401) {
            if (error.response.data.message === 'Token Expired') {
              console.error('Your session has expired. Redirecting to login...');
              setShowTokenExpiredMessage(true);
              setTimeout(() => {
                setIsVerified(false);
              }, 3000);
            } 
            else {
              setShowTokenExpiredMessage(true);
              setTimeout(() => {
                setIsVerified(false);
              }, 3000);
              console.error('Invalid token. Redirecting to login...');
            }
          } else {
            setIsVerified(false);
          }
        }
      } else {
        setIsVerified(false);
      }
    };

    verifyToken();
  }, [authToken]);

  if (isVerified === null) {
    return <LoadingSpinner />;
  }


  if (showTokenExpiredMessage) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Your session has expired</h2>
          <p className="mb-4">Please Login Again</p>
          <button onClick={logout} className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline">Login</button>
        </div>
      </div>
    );
  }

  if (!isAuthenticated || !isVerified) {
    console.log('Redirecting to login...');
    Cookies.remove('x-auth-cookie');
    localStorage.clear();
    navigate('/login');
  }

  return <Outlet />;
}

export default PrivateRoutes;
