import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar';
import saly from '../images/Saly.png';
import axios from 'axios';
import SERVER_URL from '../components/utils';
import Button from '../components/button';

export default function ForgotPassword() {
  const [formData, setFormData] = useState({
    email: '',
  });

  const [error, setError] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${SERVER_URL}/api/auth/forgotpassword`, {
        email: formData.email,
      });

      if (response.data.success) {
        setShowPopup(true);
      } else {
        // Handle API error, set error state
        setError(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
      // Handle network or unexpected errors
      setError("An error occurred. Please try again later.");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Navbar />

      <div className="flex flex-col md:flex-row">
        {/* Left side */}
        <div className="w-full md:w-1/2 py-5 md:p-10 relative hidden md:block">
          {/* Heading and paragraph */}
          <div className="text-left absolute pl-3.5 ml-3.5 md:top-1/2 md:left-52 transform -translate-x-1/2 -translate-y-1/2">
            <p className="text-xl md:text-2xl font-semibold text-black">Reset Your</p>
            <p className="text-xl md:text-4xl font-semibold text-black">Password</p>
          </div>
          {/* Vector image */}
          <div className="absolute flex flex-col top-20 left-96 pr-10 pb-2">
            <img src={saly} alt="saly" className="w-full h-full" />
          </div>
        </div>

        {/* Right side (Forgot Password form component) */}
        <div className="w-full md:mt-20 md:w-1/2 p-5 md:py-5 md:pl-40">
          <div className="max-w-md w-full md:w-10/12">
            
            {/* Display error message */}
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

            <div className="text-3xl pb-3 md:text-left">Forgot Password</div>
            <div className="mb-6 md:hidden flex justify-center">
              <img src={saly} alt="saly" className="w-1/2 h-auto" />
            </div>
            <div className="text-base pb-5 text-center md:text-left">
              <p className="pb-2">Please enter your email address. We will send you an email to reset your password</p>
            </div>
            <form className="bg-white" onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2.5 px-4 mb-5 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                  id="email"
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              {/* Display error message */}
              {error && <p className="text-red-500">{error}</p>}

              <div className="mb-6 flex items-center justify-between">
                <Button
                  className="bg-[rgba(142,75,221,255)] hover:bg-blue-950 text-white font-bold py-2.5 px-4 w-full rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Send email
                </Button>
              </div>

              <div className="text-base pb-5 text-center md:text-left">
                <p>AdviceBytes.com is secure. We treat your personal data with care. Please find our <Link to="https://www.advicebytes.com/privacy-policy" className="text-[rgba(77,71,195,255)]">Privacy Policy here</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Pop-up message */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-md text-center">
            <p className="mb-4">A link has been sent to your email</p>
            <Button
              className="bg-[rgba(142,75,221,1)] hover:bg-blue-950 text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowPopup(false)}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
