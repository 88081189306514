import React, { createContext, useState } from 'react';

export const CustomerFormContext = createContext();

export const CustomerFormProvider = ({ children }) => {
    const [userFormData, setUserFormData] = useState({
        templateId: '',
        templateName: '',
        customerPolicyData:'',
        category:'',
        userTag: []
    });

    return (
        <CustomerFormContext.Provider value={{ userFormData, setUserFormData }}>
            {children}
        </CustomerFormContext.Provider>
    );
};

export default CustomerFormContext;
