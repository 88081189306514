import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logoutIcon } from '../Icons';
import sidebarData from './sidebarData';
import avatarimg from '../images/avatarimg.jpg';
import axios from 'axios';
import Cookies from 'js-cookie';
import SERVER_URL from './utils';
import logo from '../images/AdviceBytesLogo.png';

export default function Sidebar() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    avatarUrl: avatarimg,
    email: '',
  });

  const authToken = Cookies.get('x-auth-cookie') ?? localStorage.getItem('token');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      localStorage.setItem('token', authToken);
      if (!authToken) {
        console.log('No Auth Token found');
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData) setUser(userData);
        return;
      }

      try {
        const response = await axios.get(`${SERVER_URL}/api/auth/userinfo`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        const userData = response.data;
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
        console.log('User data:', userData);
      } catch (error) {
        console.error('Fetch user details error:', error.message);
      }
    };

    fetchUserDetails();
  }, [authToken]);

  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      localStorage.clear();
      Cookies.remove('x-auth-cookie');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="fixed w-full bg-white shadow z-50">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center">
              <img
                src={logo}
                alt="AdviceBytes Logo"
                className="h-8 w-auto sm:h-10 cursor-pointer"
                onClick={() => navigate('/')}
              />
              <h1
                className="text-xl lg:text-2xl font-bold ml-2 cursor-pointer"
                onClick={() => navigate('/')}
              >
                AdviceBytes
              </h1>
            </div>
            <button
              onClick={toggleSidebar}
              className="md:hidden z-50 bg-transparent p-2 transition-all duration-300 ease-in-out right-4 top-4"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      <nav
        className={`fixed md:relative md:w-64 w-full bg-[rgba(245,246,250,255)] shadow h-full z-40 transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } md:translate-x-0 transition-transform duration-300 ease-in-out left-0 top-16 md:top-0`}
      >
        <div className="flex flex-col md:items-start h-full p-4 overflow-y-auto md:mt-14">
          <div className="flex items-center mt-2 mb-8 ml-4 md:ml-2">
            <div>
              <img
                src={user.avatarUrl}
                alt="avatar"
                className="h-16 w-16 md:h-12 md:w-12 rounded-full"
              />
            </div>
            <div className="flex flex-col ml-4 md:ml-2 font-medium">
              <span className="text-lg md:text-base">
                {user.firstname} {user.lastname}
              </span>
              <span className="text-sm md:text-xs">{user.email}</span>
            </div>
          </div>
          <div className="flex flex-col space-y-6 mx-4 md:mx-3">
            {sidebarData.map((item, index) => (
              <Link
                key={index}
                onClick={toggleSidebar}
                to={item.to}
                className="hover:text-mobile-color text-[rgba(180,180,189,255)] flex items-center text-lg md:text-xs"
              >
                {item.icon}
                <span className="ml-6 md:ml-5 text-lg md:text-sm font-semibold">{item.text}</span>
              </Link>
            ))}
            <Link
              to="/login"
              onClick={handleLogout}
              className="hover:text-mobile-color font-semibold text-[rgba(180,180,189,255)] flex items-center text-lg md:text-sm"
            >
              {logoutIcon}
              <span className="ml-6 md:ml-5">Logout</span>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
