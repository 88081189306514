import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../components/sidebar'

export default function NotaMember() {
  return (
    <div className="flex h-screen">
    <Sidebar />
    <div className="flex-1 p-4 overflow-auto">
      {/* Dashboard content goes here */}
      <div className='m-12 w-1/2 '>
      <h2 className="text-3xl  font-bold mb-8 ">Build Your Policies</h2>
      <h2 className='mb-10'>
                The fastest way to draft,refine and build your company policies.
            </h2>

            
            <p className='mb-10 '>

                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc auctor, justo vel ultrices aliquet, nunc odio bibendum nunc, in tincidunt nunc nunc nec libero
            </p>

      </div>

      <div className="flex items-center m-32 ">
             <Link to ='/membership'>
                <button
                  className="bg-[rgba(142,75,221,255)] hover:bg-blue-950  text-white font-bold py-3 px-7 rounded focus:outline-none focus:shadow-outline "
                  type="submit"
                >
                  See Membership Plans
                </button>
                </Link>
              </div>
    </div>

  </div>

     





  )
}
