import React, { useState,useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../components/navbar';
import saly from '../images/Saly.png';
import { EyeIcon, EyeOffIcon } from '../Icons';
import SERVER_URL from '../components/utils';
import Button from '../components/button';

export default function Signup() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  useEffect(() => {
    if (error) {
      alert(`Error: ${error}`);
      setError('');
    }
  }, [error]);

  const handleAlert = () => {
    if (error) {
      alert(`Error: ${error}`);
      setError('');
    }
  };

  const handleInputValidation = (e) => {
    const { name, value } = e.target;
  
  



    const validateField = (field, minLength, errorState) => {
      if (value.trim().length < minLength) {
        errorState(`Field should be at least ${minLength} characters`);
      } else {
        errorState('');
      }
    };
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(value.match(emailRegex) ? '' : 'Invalid email address');
    }

    if (name === 'firstname') {
      validateField('firstname', 2, setFirstNameError);
    }

    if (name === 'lastname') {
      validateField('lastname', 2, setLastNameError);
    }

    if (name === 'password') {
      if (value.trim().length < 8) {
        setPasswordError('Password must be at least 8 characters');
      } else if (!value.match(/^(?=.*\d)(?=.*[A-Z])/)) {
        setPasswordError('Password must contain at least one number and one uppercase letter');
      } else {
        setPasswordError('');
      }
    }

    if (name === 'confirmPassword') {
      setConfirmPasswordError(value !== formData.password ? 'Passwords do not match' : '');
    }
  };

  const loginWithGoogle = async () => {
    try {
      window.location.href = `${SERVER_URL}/auth/googleauth`;
    } catch (error) {
      setLoading(false);
      handleAlert();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');

    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    setPasswordError('');
    setLoading(true);

    try {
      const response = await fetch(`${SERVER_URL}/api/auth/createuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          password: formData.password,
        }),
      });

      const json = await response.json();

      if (json.success) {
        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmPassword: '',
        });
        navigate('/VerificationPage');
      } else {
        if (json.errors && json.errors.length > 0) {
          const validationErrors = json.errors.map((error) => error.msg);
          setError(validationErrors.join(', '));
        } else if (json.error) {
          setError(json.error);
        } else {
          setError('An error occurred. Please try again later.');
        }
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
      handleAlert();
    }
  };
  
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFieldFocus = (errorState) => {
    errorState('');
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col md:flex-row">
        {/* Left side */}
        <div className="w-full md:w-1/2 py-5 md:p-10 relative hidden md:block">
          <div className="text-left absolute top-1/2 md:top-1/2 left-1/2 md:left-52 transform -translate-x-1/2 -translate-y-1/2">
            <h1 className="text-2xl md:text-4xl font-bold text-black px-4 pb-3">Sign Up to </h1>
            <h3 className="text-xl md:text-3xl text-black px-5 pb-3">Advice Bytes</h3>
            <p className="pr-2 pl-5 pt-2 md:pt-6 text-base">If you already have an account</p>
            <p className="px-5">
              You can <Link to="/login" className="text-[rgba(77,71,195,255)]">Login here!</Link>
            </p>
          </div>
          <div className="absolute flex flex-col top-20 left-96 pr-10 pb-2">
            <img src={saly} alt="saly" className="w-full h-full" />
          </div>
        </div>
  
        {/* Right side (Signup form component) */}
        <div className="w-full md:mt-10 md:w-1/2 p-5 md:py-5 md:pl-40">
          <div className="max-w-md w-full md:w-10/12">
            <div className="text-3xl pb-3 md:text-left">Sign Up</div>
            <div className="mb-6 md:hidden flex justify-center">
              <img src={saly} alt="saly" className="w-1/2 h-auto" />
            </div>
            <form className="bg-white" onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  className={`shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
                    emailError ? 'border-red-500' : ''
                  }`}
                  id="email"
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputValidation(e);
                  }}
                  onBlur={handleInputValidation}
                  onFocus={() => handleFieldFocus(setEmailError)}
                />
                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
              </div>
  
              <div className="mb-4">
                <input
                  className={`shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
                    firstNameError ? 'border-red-500' : ''
                  }`}
                  id="firstname"
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputValidation(e);
                  }}
                  onBlur={handleInputValidation}
                  onFocus={() => handleFieldFocus(setFirstNameError)}
                />
                {firstNameError && <p className="text-red-500 text-sm mt-1">{firstNameError}</p>}
              </div>
  
              <div className="mb-4">
                <input
                  className={`shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
                    lastNameError ? 'border-red-500' : ''
                  }`}
                  id="lastname"
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputValidation(e);
                  }}
                  onBlur={handleInputValidation}
                  onFocus={() => handleFieldFocus(setLastNameError)}
                />
                {lastNameError && <p className="text-red-500 text-sm mt-1">{lastNameError}</p>}
              </div>
  
              <div className="mb-6 relative">
                <div className="relative w-full">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
                      passwordError ? 'border-red-500' : ''
                    }`}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputValidation(e);
                    }}
                    onBlur={handleInputValidation}
                  />
                  <span
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => togglePasswordVisibility('password')}
                  >
                    {showPassword ? EyeIcon : EyeOffIcon}
                  </span>
                </div>
                {passwordError && <p className="text-red-500 text-sm mt-1">{passwordError}</p>}
              </div>
  
              <div className="mb-6 relative">
                <div className="relative w-full">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
                      confirmPasswordError ? 'border-red-500' : ''
                    }`}
                    id="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputValidation(e);
                    }}
                    onBlur={handleInputValidation}
                  />
                  <span
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => togglePasswordVisibility('confirmPassword')}
                  >
                    {showConfirmPassword ? EyeIcon : EyeOffIcon}
                  </span>
                </div>
                {confirmPasswordError && <p className="text-red-500 text-sm mt-1">{confirmPasswordError}</p>}
              </div>
  
              <div className="mb-6 flex items-center">
                <Button
                  className={`bg-[rgba(142,75,221,255)] hover:bg-blue-950 text-white font-bold py-2.5 px-4 w-full rounded focus:outline-none focus:shadow-outline ${
                    loading && !error ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  type="submit"
                  disabled={loading && !error}
                >
                  {loading && !error ? (
                    <div className="flex items-center justify-center">
                      <div className="animate-spin border-t-2 border-white rounded-full h-4 w-4"></div>
                    </div>
                  ) : (
                    'Register'
                  )}
                </Button>
              </div>
            </form>
  
            <div className="flex items-center justify">
              <button
                aria-label="Sign up with Google"
                className="flex items-center bg-white border border-button-border-light rounded-md w-full py-0.5 justify-center"
                onClick={loginWithGoogle}
              >
                <div className="flex items-center justify-center bg-white w-9 h-9 rounded-l">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5">
                    <title>Sign up with Google</title>
                    <desc>Google G Logo</desc>
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      className="fill-google-logo-blue"
                    ></path>
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      className="fill-google-logo-green"
                    ></path>
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      className="fill-google-logo-yellow"
                    ></path>
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      className="fill-google-logo-red"
                    ></path>
                  </svg>
                </div>
                <span className="text-sm text-google-text-gray tracking-wider">Sign up with Google</span>
              </button>
            </div>
  
            <div className="mb-4 text-center block md:hidden">
              <p className="text-base">If you already have an account</p>
              <p className="text-base">
                You can <Link to="/login" className="text-[rgba(77,71,195,255)]">Login here!</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}
