import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from './screens/Login';
import Signup from './screens/Signup';
import VerificationPage from "./screens/Verification";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Dashboard from "./screens/Dashboard";
import Documents from "./screens/Documents";
import Settings from "./screens/Settings";
import Membership from "./screens/Membership";
import Insights from "./screens/Insights";
import Policies from "./screens/Policies";
import NotaMember from "./screens/NotaMember";
import Template from "./screens/Template";
import Form from "./screens/Form";
import Courses from "./screens/Courses";
import PrivateRoute from "./components/privateRoute";
import { registerLicense } from '@syncfusion/ej2-base';
import PublicRoute from "./components/publicRoute";
import PolicyTemp from "./screens/policyTemp";
import CustomerForm from "./screens/CustomerForm";
import { FormProvider } from "./contexts/FormContext";
import { CustomerFormProvider } from './contexts/CustomerFormContext';
import  CreateCourses  from './screens/CreateCourses';
import AdminRoutes from './components/adminRoute';
import NotFound from "./components/notFound";

registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxednRQQ2leUkRzWUc=');

function App() {
  return (
    <Router>
      <div>
        <FormProvider>
          <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/" element={<PublicRoute />}>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
            </Route>

            <Route path="/VerificationPage" element={<VerificationPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token/:resetToken" element={<ResetPassword />} />

            <Route path="/" element={<PrivateRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="policyTemp/:name" element={<PolicyTemp />} />
              <Route path="documents" element={<Documents />} />
              <Route path="policies" element={<Policies />} />
              <Route path="settings" element={<Settings />} />
              
              <Route path="membership" element={<Membership />} />
              <Route path="insights" element={<Insights />} />
              <Route path="notamember" element={<NotaMember />} />
              <Route path="customerform/:id" element={
                <CustomerFormProvider>
                  <CustomerForm />
                </CustomerFormProvider>
              } />
              <Route path="template/:id" element={
                <CustomerFormProvider>
                  <Template />
                </CustomerFormProvider>
              } />
            </Route>
            <Route path="/" element={<AdminRoutes />} >
            <Route path="courses" element={<Courses />} />
            <Route path="createcourses/:courseId" element={<CreateCourses />} />
              <Route path="CreateCourses" element={<CreateCourses />} />
              <Route path="form" element={<Form />} />
              <Route path="form/:name" element={<Form />} />
              </Route>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </FormProvider>
      </div>
    </Router>
  );
}

export default App;
