import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import SERVER_URL from '../components/utils';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../components/button';

export default function Policies() {
  const [user, setUser] = useState({});
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('success')) {
      setSuccessMessage('Form submitted successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  }, [location]);

  useEffect(() => {
    const authToken = localStorage.getItem('token');
    const fetchUserDetails = async () => {
      try {
        if (!authToken) {
          console.error('No Auth Token found');
          return;
        }

        const response = await fetch(`${SERVER_URL}/api/auth/userinfo`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user information');
        }

        const userData = await response.json();
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      } catch (error) {
        console.error('Fetch user details error:', error.message);
      }
    };

    const fetchTemplates = async () => {
      try {
        const templatesResponse = await fetch(`${SERVER_URL}/api/getTemplate`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!templatesResponse.ok) {
          throw new Error('Failed to fetch templates');
        }
        const templatesData = await templatesResponse.json();
        setTemplates(templatesData);
      } catch (error) {
        console.error('Fetch templates error:', error.message);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
    fetchTemplates();
  }, []);

  const categories = [...new Set(templates.map(template => template.category))];

  return (
    <div className="flex h-screen  ">
      <Sidebar user={user} />
      <div className="flex-1 p-4 overflow-auto mt-14 md:mx-2">
        <div className="m-2">
          <div className="flex items-center justify-between md:justify-start mb-4 md:mb-6">
            <h1 className="text-3xl font-normal ml-2 text-mobile-color md:text-gray-800 whitespace-nowrap">Policies</h1>

          </div>
          {user.isAdmin && (
            <Link to="/form">
              <Button className="bg-[rgba(142,75,221,255)] hover:bg-blue-700 text-white font-bold py-2 px-6 rounded">
                Create Policy Template
              </Button>
            </Link>
          )}
        </div>
        {successMessage && (
          <div className="bg-green-500 text-white px-4 py-2 rounded mb-4">
            {successMessage}
          </div>
        )}

        {
          isLoading ? (<LoadingSpinner />) : (
            categories.map(category => (
              <div key={category} className="bg-[rgba(245,246,250,255)] m-4 p-4 rounded-xl shadow-md">
                <h1 className="mb-5">{category}</h1>
                <div className="overflow-auto max-h-72 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {templates
                    .filter(template => template.category === category)
                    .map(template => (
                      <div key={template.id} className="bg-white text-gray-800 rounded-lg flex flex-col">
                        <div className="rounded-t-lg p-2 mb-2 bg-gray-200">
                          <h3 className="text-sm font-semibold">{template.templateName}</h3>
                        </div>
                        <div className="px-4 flex flex-col flex-grow">
                          <p className="text-gray-600 mb-4 text-xs">{template.description}</p>
                          <div className="mt-auto flex justify-end">
                            <Link to={`/customerform/${template.templateId}`}>
                              <Button className="bg-white text-xs text-purple-700 border-2 m-1 py-1 px-3.5 rounded-lg ">
                                Create Policy
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )))}



      </div>
    </div>

  );
}
