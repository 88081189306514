import React, { useState, useRef, useEffect, useContext } from 'react';
import { TreeViewComponent, ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import { microarrowLeft, microarrowRight } from '../Icons';
import logo from "../images/AdviceBytesLogo.png";
import { Link, useNavigate, useParams} from 'react-router-dom';
import SERVER_URL from '../components/utils';
import { FormContext } from '../contexts/FormContext';
import { HtmlEditor, Image, Inject, QuickToolbar, RichTextEditorComponent, Toolbar, PasteCleanup, MarkdownEditor, RichTextEditor } from '@syncfusion/ej2-react-richtexteditor';
import Button from '../components/button';
RichTextEditor.Inject(Image, MarkdownEditor, Toolbar);
enableRipple(true);

const Form = () => {
  const { formData, setFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const { name } = useParams();
const decodedNodeName = decodeURIComponent(name);

  const [hierarchicalData, setHierarchicalData] = useState(() => {
    const storedData = localStorage.getItem('hierarchicalData');
    return storedData ? JSON.parse(storedData) : [{
      id: "01",
      name: 'CCTV policy',
      expanded: true,
      hasAttribute: {}, // To be colored purple
      data: '', // New 'data' field initialized with an empty string
      category: 'Category Name',
    }];
  });

  useEffect(() => {
    localStorage.setItem('hierarchicalData', JSON.stringify(hierarchicalData));
  }, [hierarchicalData]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [categoryName, setCategoryName] = useState("Category Name");
  const [editingFormName, setEditingFormName] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const authToken = localStorage.getItem('token');
 

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEditingFormName(false);
      updateCategoryName();
    }
  };

  const rteObj = useRef({});
  const treeObjRef = useRef(null);
  const menuObjRef = useRef(null);

  const updateURL = (nodeName) => {
    navigate(`/form/${encodeURIComponent(nodeName)}`);
  };

 


  const getFormattedContent = async () => {
    const rteValue = rteObj.current[selectedItem.id].getHtml();
    const templateData = await fetchTemplateData(selectedItem.id);
    const question = templateData.questionnaire;
    setFormData({ data: rteValue, category: categoryName, name: selectedItem.name, id: selectedItem.id, questionnaire: question})
    ;
    createTemplate({
      templateId: selectedItem.id,
      templateName: selectedItem.name,
      category: categoryName,
      data: rteValue,
      questionnaire: question,
    });
    // setHierarchicalData(prevData =>
    //   prevData.map(item =>
    //     item.id === selectedItem.id ? { ...item, data: rteValue } : item
    //   )
    // );
    // localStorage.setItem('hierarchicalData', JSON.stringify(hierarchicalData));
    

    navigate(`/policyTemp/${selectedItem.name}`);
  };

  const PreviewContent = ({ richtextContent }) => (
    <div className="border-2 shadow-lg p-8 bg-white w-11/12 h-full overflow-scroll">
      <div className="prose max-w-fit" dangerouslySetInnerHTML={{ __html: richtextContent }} />
    </div>
  );

  const togglePreviewMode = () => {
    setIsPreviewMode(!isPreviewMode);

    const rteContent = rteObj.current[selectedItem.id].getHtml();
    setFormData(prevData => ({
      ...prevData,
      [selectedItem.id]: {
        ...prevData[selectedItem.id],
        data: rteContent,
      },
    }));
  };


  useEffect(() => {

    if (decodedNodeName) {
      const selectedNode = hierarchicalData.find(item => item.name === decodedNodeName);
      if (selectedNode) {
        setSelectedItem(selectedNode);
        setShowForm(true);
       
  
        if (selectedNode.category) {
          setCategoryName(selectedNode.category);
        } else {
          setCategoryName("Category Name");
        }
  
        const cachedData = formData[selectedNode.id];
        if (cachedData && cachedData.data) {
          rteObj.current[selectedNode.id].value = cachedData.data; 
        } else {
          fetchTemplateData(selectedNode.id).then(templateData => {
            if (templateData) {
              rteObj.current[selectedNode.id].value = templateData.data;
            } else {
              rteObj.current[selectedNode.id].value = selectedNode.data;
            }
            
          });
        }
      }
    }
  }, [decodedNodeName, hierarchicalData, formData]);
  
  
  useEffect(() => {
    fetchTemplateNames();
  }, []);

  const createTemplate = async (template) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/createTemplate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(template),
      });

      if (response.ok) {
        console.log('Template created successfully');
      } else {
        console.error('Failed to create template');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };
  const fetchTemplateNames = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getTemplate`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const templates = await response.json();
        // console.log(templates);

        const hierarchicalData = templates.map((template, index) => ({
          id: (index + 1).toString().padStart(2, '0'),
          name: template.templateName,
          category: template.category,
          data: template.data,
          questionnaire: template.questionnaire,
        }));

        setHierarchicalData(hierarchicalData);
      } else {
        console.error('Failed to fetch template names');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };

  const fetchTemplateData = async (templateId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getTemplateById/${templateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
       
      } else {
        console.error('Failed to fetch template');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };

  const deleteTemplate = async (templateId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/deleteTemplate/${templateId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        alert('Template deleted successfully');
      } else {
        alert('Failed to delete template');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCategory = async (templateId, category) => {
    try{
      const response = await fetch(`${SERVER_URL}/api/updateCategory/${templateId}`, {
        method:'PATCH',
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,

        },
        body: JSON.stringify({ category })});
        if(response.ok){
          console.log("Category Update")
        }
        else{
          console.log("Category Did not update")
        }
    }
    catch(error){
      console.log(error);
    }
  
  }

  const updateCategoryName = () => {
    if (selectedItem) {
      setHierarchicalData(prevData =>
        prevData.map(item =>
          item.id === selectedItem.id ? { ...item, category: categoryName } : item
        )
      );
      updateCategory(selectedItem.id, categoryName);
    }
  };


  

  const nodeclicked = async (args) => {
    const uid = args.node.getAttribute('data-uid');
    const selectedData = hierarchicalData.find(item => item.id === uid);

    if (selectedData) {
      setSelectedItem(selectedData);
      setShowForm(true);

      if (selectedData.category) {
        setCategoryName(selectedData.category);
      } else {
        setCategoryName("Category Name");
      }

      updateURL(selectedData.name);
    }
  };

  useEffect(() => {
    if (selectedItem && rteObj.current[selectedItem.id]) {
      if (selectedItem.data) {
        rteObj.current[selectedItem.id].value = selectedItem.data;
      } else {
        fetchTemplateData(selectedItem.id).then(templateData => {
          if (templateData) {
            rteObj.current[selectedItem.id].value = templateData.data;
          }
        });
      }
    }
  }, [selectedItem]);


  const menuclick = (args) => {
    const targetNodeId = treeObjRef.current.selectedNodes[0];
    if (!targetNodeId) {
      console.error('No target node selected');
      return;
    }
  
    if (args.item.text === "Add New Item") {
      const nodeId = hierarchicalData.length + 1;
      
      const newItem = { id: nodeId.toString().padStart(2, '0'), name: "Policy Name", category:" Category Name", data: " " };
      setHierarchicalData([...hierarchicalData, newItem]);
      setSelectedItem(newItem);
      updateURL(newItem.name);
     
      createTemplate({
        templateId: newItem.id,
        templateName: newItem.name,
        category: newItem.category,
        data: newItem.data, 
      });

  
    } else if (args.item.text === "Remove Item") {
      const updatedData = hierarchicalData.filter(item => item.id !== targetNodeId);
      deleteTemplate(targetNodeId);
      setHierarchicalData(updatedData);
      setSelectedItem(null);
      updateURL('');

    } else if (args.item.text === "Rename Item") {
      const newName = prompt("Enter new name", selectedItem.name);
      if (newName) {
        const updatedData = hierarchicalData.map(item => item.id === targetNodeId ? { ...item, name: newName } : item);
        setHierarchicalData(updatedData);
        const updatedItem = updatedData.find(item => item.id === targetNodeId);
        setSelectedItem(updatedItem);

        const templateData = rteObj.current[updatedItem.id].getHtml();
        createTemplate({
          templateId: updatedItem.id,
          templateName: newName,
          category: updatedItem.category,
          data: templateData, 
        });
        updateURL(newName);
      }
    }
  };

  const onNodeEdited = (args) => {
    const editedNode = args.nodeData;
    const updatedData = hierarchicalData.map(item => {
      if (item.id === editedNode.id) {
        createTemplate({
          templateId: item.id,
          templateName: editedNode.text,
          category: item.category,
          data:item.data??''
        });
        return { ...item, name: editedNode.text,data:item.data??'' };
      
      }
      return item;
    });
    setHierarchicalData(updatedData);
    const renamedItem = updatedData.find(item => item.id === editedNode.id);
    setSelectedItem(renamedItem);
    console.log(renamedItem.name);
    updateURL(renamedItem.name);
  };
  

  
  
  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };


  // const beforeopen = (args) => {
  // const targetNodeId = treeObjRef.current.selectedNodes[0];
  // const targetNode = document.querySelector('[data-uid="' + targetNodeId + '"]');
  //   menuObjRef.current.enableItems(['Remove Item'], !targetNode.classList.contains('remove'));
  //   menuObjRef.current.enableItems(['Rename Item'], !targetNode.classList.contains('rename'));
  // };

  const beforeopen = (args) => {
    const targetNodeId = treeObjRef.current.selectedNodes[0];
    if (!targetNodeId) {
      console.error('No target node selected');
      return;
    }
  
    const targetNode = document.querySelector(`[data-uid="${targetNodeId}"]`);
    if (!targetNode) {
      console.error('Target node not found');
      return;
    }
  
    menuObjRef.current.enableItems(['Remove Item'], !targetNode.classList.contains('remove'));
    menuObjRef.current.enableItems(['Rename Item'], !targetNode.classList.contains('rename'));
  };
  

  const renderForm = () => (
    <div className="border-2 shadow-lg p-4 h-full">
      
      <form className="relative">
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2" htmlFor="contentHeading">
            {selectedItem.name}
          </label>
        </div>
        <RichTextEditorComponent
          id={selectedItem ? `RTE_${selectedItem.id}` : ''}
          ref={(richtexteditor) => {
            if (richtexteditor && selectedItem) {
              rteObj.current[selectedItem.id] = richtexteditor;
            }
          }}
          value={formData[selectedItem?.id]?.data || ''}
          onChange={(e) => {
            const data = e.value;
            setFormData(prevData => ({
              ...prevData,
              [selectedItem.id]: {
                ...prevData[selectedItem.id],
                data: data,
              },
            }));
            setHierarchicalData(prevData =>
              prevData.map(item =>
                item.id === selectedItem.id ? { ...item, data: data } : item
              )
            );
          }}
          toolbarSettings={{ items: ['Bold', 'Italic', 'Underline', 'Formats', 'Alignments', 'Undo', 'Redo'] }}
          height="400px"
          placeholder="Enter content here..."
        >
          <Inject services={[HtmlEditor, Toolbar, Image, QuickToolbar, PasteCleanup, MarkdownEditor]} />
        </RichTextEditorComponent>


        <div className="fixed flex justify-end mt-2 space-x-2 bottom-8 right-36 p-4">
          <button type="button" onClick={getFormattedContent} className="bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] hover:from-white hover:to-white hover:text-[rgba(133,65,222,255)]  hover:shadow-xl border-2 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out ">
            Next
          </button>
         
        </div>
      </form>
    </div>
  );

  return (
    <div className="flex h-screen relative overflow-y-scroll">
      <div className={`inset-y-0 z-10 p-4 bg-[rgba(245,246,250,255)] shadow max-h-screen relative ${isSidebarExpanded ? 'w-28' : 'w-4'}`}>
        {/* Logo */}
        <div className='flex flex-col items-center'>
          <img src={logo} alt='AdviceBytes' className={`${isSidebarExpanded ? 'w-12 h-12' : 'absolute w-5 h-5 '}`} />
          <p className={` ${isSidebarExpanded ? 'text-xs font-semibold' : 'hidden'}`}>AdviceBytes</p>
        </div>
        <button onClick={toggleSidebar} className={`absolute top-72 right-0 bg-gray-300 px-2 py-1 rounded-full z-20`}>
          {isSidebarExpanded ? microarrowLeft : microarrowRight}
        </button>
        <Link to="/policies">
        <button className={`${isSidebarExpanded ? "absolute bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] top-96 mt-28 hover:bg-blue-700 text-white text-xs font-bold py-2 px-2 rounded" : "hidden"}`}>
          
            Policy Page
         
        </button>
        </Link>
      </div>
      {/* Existing Sidebar */}
      <div className='w-1/4 p-4 bg-[rgba(245,246,250,255)] shadow overflow-y-scroll max-h-fit relative'>
        <div id='sidebar'>
          <div onDoubleClick={() => setEditingFormName(true)}>
            {editingFormName ? (
              <input
                type="text"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                onBlur={() => {setEditingFormName(false);
                  updateCategoryName();
                }}
                autoFocus
                onKeyDown={handleKeyDown}
              />
            ) : (
              <span className='text-[rgba(142,75,221,255)] text-xl font-extrabold'>{categoryName}</span>
            )}
          </div>
          <TreeViewComponent
            fields={{ dataSource: hierarchicalData, id: 'id', text: 'name', child: 'subChild', htmlAttributes: 'hasAttribute' }}
            ref={treeObjRef}
            nodeClicked={nodeclicked}
            nodeEdited={onNodeEdited}
          />
          <ContextMenuComponent
            id="contextmenusidebar"
            target='#sidebar'
            items={[
              { text: 'Add New Item' },
              { text: 'Rename Item' },
              { text: 'Remove Item' },
            ]}
            beforeOpen={beforeopen}
            select={menuclick}
            ref={menuObjRef}
          />
        </div>
      </div>
      {/* Form */}
     <div className="flex-1 p-8 overflow-hidden">
        {
         
        showForm && selectedItem ? (isPreviewMode ? <PreviewContent richtextContent={formData[selectedItem?.id]?.data || ''} /> : renderForm()) : (
          <p>Select an item from the sidebar</p>
        )}
      </div>
 <div className="fixed bottom-8 right-7 p-4">
 {
         
         showForm && selectedItem ?(
        <Button
          onClick={togglePreviewMode}
          className={isPreviewMode  ? "bg-[rgba(142,75,221,255)] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" : "bg-[rgba(142,75,221,255)] hover:bg-blue-700 text-white font-bold py-2 mr-2 px-4 rounded"}
        >
          {isPreviewMode ? 'Edit' : 'Preview'}
        </Button>)
        :<div></div>}
      </div>
  


    </div>
  );
};

export default Form;
