import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/navbar';
import saly from '../images/Saly.png';
import axios from 'axios';
import SERVER_URL from '../components/utils';
import { EyeIcon,EyeOffIcon } from '../Icons';
import Button from '../components/button';

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { resetToken } = useParams();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    handleInputValidation(e);
  };

  const handleInputValidation = (e) => {
    const { name, value } = e.target;

    if (name === 'password') {
      if (value.trim().length < 8) {
        setPasswordError('Password must be at least 8 characters');
      } else if (!value.match(/^(?=.*\d)(?=.*[A-Z])/)) {
        setPasswordError('Password must contain at least one number and one uppercase letter');
      } else {
        setPasswordError('');
      }
    }


    if (name === 'confirmPassword') {
      setConfirmPasswordError(value !== formData.password ? 'Passwords do not match' : '');
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL}/api/auth/resetpassword/${token}/${resetToken}`, {
        password: formData.password
      });

      if (response.data.success) {
        alert('Password reset successful');
        navigate('/login');
      } else {
        alert(response.data.msg);
      }
    } catch (error) {
      alert('Error resetting password');
    }
  };
  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };


  useEffect(() => {
    // Fetch additional data or perform actions on component mount if needed
  }, []);

  return (
    <div>
      <Navbar />
  
      <div className="flex flex-col md:flex-row">
    {/* Left side */}
    <div className="w-full md:w-1/2 py-5 md:p-10 flex flex-col md:flex-row items-center md:items-center justify-center">
      {/* Logo space */}
   {/* Heading and paragraph */}
   <div className=" md:text-center md:justify-center md:ml-12 mb-8 md:mb-56">
        <p className="text-xl md:text-3xl font-semibold text-black">Reset Your</p>
        <p className="text-xl md:text-4xl font-semibold text-black">Password</p>
      </div>

      {/* Vector image */}
      <div className="md:ml-32 mb-12 md:mt-0">
        <img src={saly} alt="saly" className="w-50 h-60 md:w-auto md:h-auto" />
      </div>
    </div>

        {/* Right side (Reset password form component) */}
        <div className="w-full md:w-6/12 p-5 md:py-5 md:pl-28 flex items-center justify-center">
      <div className="max-w-md w-full">
        <div className="text-sm md:text-base pb-5 text-center md:text-left font-semibold">
      <p className="pb-2">Please enter your new password.</p>
    </div>
    <form className="py-6 rounded-lg">
  {/* Password input field */}
  <div className="relative mb-4 w-10/12">
    <input
      className={`shadow appearance-none border rounded w-full py-3.5 px-4 pr-10 mb-5 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
        passwordError ? 'border-red-500' : ''
      }`}
      id="password"
      type={showPassword ? 'text' : 'password'}
      placeholder="Enter New Password"
      name="password"
      value={formData.password}
      onChange={(e) => {
        handleChange(e);
        handleInputValidation(e);
      }}
      onBlur={handleInputValidation}
    />
    <span
      className="absolute right-3 top-1/3 transform -translate-y-1/2 cursor-pointer"
      onClick={() => togglePasswordVisibility('password')}
    >
      {showPassword ? EyeIcon : EyeOffIcon}
    </span>
    {passwordError && <p className="text-red-500 text-sm mt-1">{passwordError}</p>}
  </div>

  {/* Confirm password input field */}
  <div className="relative mb-4 w-10/12">
    <input
      className={`shadow appearance-none border rounded w-full py-3.5 px-4 pr-10 mb-5 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm ${
        confirmPasswordError ? 'border-red-500' : ''
      }`}
      id="confirmPassword"
      type={showConfirmPassword ? 'text' : 'password'}
      placeholder="Confirm New Password"
      name="confirmPassword"
      value={formData.confirmPassword}
      onChange={(e) => {
        handleChange(e);
        handleInputValidation(e);
      }}
      onBlur={handleInputValidation}
    />
    <span
      className="absolute right-3 top-1/3 transform -translate-y-1/2 cursor-pointer"
      onClick={() => togglePasswordVisibility('confirmPassword')}
    >
      {showConfirmPassword ? EyeIcon : EyeOffIcon}
    </span>
    {confirmPasswordError && <p className="text-red-500 text-sm mt-1">{confirmPasswordError}</p>}
  </div>

  {/* Submit button */}
  <div className="flex items-center justify-center md:justify-start w-10/12">
    <Button
      className="bg-[rgba(142,75,221,1)] hover:bg-blue-950 text-white font-bold py-3 px-4 w-full md:w-auto rounded focus:outline-none focus:shadow-outline"
      type="submit"
    >
      Reset Password
    </Button>
  </div>

  {/* Privacy policy */}
  <div className="flex items-center pt-4 justify-center md:justify-start w-10/12">
    <p className="text-sm text-center md:text-left">
      AdviceBytes.com is secure. We treat your personal data with care. Please find our 
      <a href="/privacy-policy" className="text-blue-500 hover:underline ml-1">Privacy Policy</a>
      here.
    </p>
  </div>
</form>

  </div>
</div>

      </div>
    </div>
  );
  
}