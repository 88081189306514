import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import { Link } from 'react-router-dom';
import SERVER_URL from '../components/utils';


function Template() {
  const { id } = useParams();
  const [template, setTemplate] = useState(null);
  const authToken = localStorage.getItem('token');

  useEffect(() => {
   
const fetchTemplate = async () => {
    try {
        const response = await fetch(`${SERVER_URL}/api/getTemplateById/${id}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch template');
        }
        const templateData = await response.json();
       
        setTemplate(templateData);
    } catch (error) {
        console.error('Fetch template error:', error.message);
    }
};


    fetchTemplate();
  }, [id]);

  if (!template) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen">
  <Sidebar />
  <div className="flex-1 p-4 overflow-auto">
    <div className="bg-gray-800 text-white shadow-lg p-4 flex items-center justify-center rounded-t-lg">
      <h2 className="text-xl font-semibold">{template.templateName}</h2>
    </div>
    <div className="mt-4">
      <p className="text-gray-600 mb-2">Category: {template.category}</p>
      <div className="bg-white shadow-md p-4 rounded-lg">
        <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: template.data }} />
      </div>
    </div>
    <div>
      <Link to="/policies">
      <button className="bg-[rgba(142,75,221,255)] bottom-48  hover:bg-blue-700 text-white font-bold py-2 px-6 rounded mt-4">
        Back to policy page
      </button>
      </Link>
    </div>
  </div>
</div>

  );
}

export default Template;
