import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import SERVER_URL from '../components/utils';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../components/button';
import { pencilIcon,searchIcon   } from '../Icons';

export default function Courses() {
  const [user, setUser] = useState({});
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setEditIsModalOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({ courseName: '', courseCategory: '', courseDescription: '' });
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const location = useLocation();
  const authToken = localStorage.getItem('token');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('success')) {
      setSuccessMessage('Form submitted successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  }, [location]);

  useEffect(() => {
    // const authToken = localStorage.getItem('token');

    const fetchUserDetails = async () => {
      try {
        if (!authToken) {
          console.error('No Auth Token found');
          return;
        }

        const response = await fetch(`${SERVER_URL}/api/auth/userinfo`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user information');
        }

        const userData = await response.json();
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      } catch (error) {
        console.error('Fetch user details error:', error.message);
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/getCourses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch courses');
        }

        const coursesData = await response.json();
        setCourses(coursesData);
      } catch (error) {
        console.error('Fetch courses error:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/getCategories`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const categoriesData = await response.json();
        console.log('categories:', categoriesData);
        setCategories(categoriesData);
        console.log('categories:', categories);
      } catch (error) {
        console.error('Fetch categories error:', error.message);
      }
    };

    fetchUserDetails();
    fetchCourses();
    fetchCategories();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openEditModal = async (courseId) => {
    setSelectedCourseId(courseId);
    await fetchCourseData(courseId);
    setEditIsModalOpen(true);
  };
  const closeEditModal = () => setEditIsModalOpen(false);


  const fetchCourseData = async (courseId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getCourseById/${courseId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch course data');
      }

      const courseData = await response.json();
      setNewCourse({
        courseName: courseData.courseName,
        courseCategory: courseData.courseCategory,
        courseDescription: courseData.courseDescription,
      });
    } catch (error) {
      console.error('Error fetching course data:', error.message);
    }
  };

  const handleInputChange = (e) => {
    setNewCourse({ ...newCourse, [e.target.name]: e.target.value });
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem('token');
      const response = await fetch(`${SERVER_URL}/api/createCourse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(newCourse),
      });

      if (!response.ok) {
        throw new Error('Failed to create course');
      }

      const createdCourse = await response.json();
      setCourses([...courses, createdCourse]);
      setSuccessMessage('Course created successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      closeModal();
    } catch (error) {
      console.error('Error creating course:', error.message);
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem('token');
      const response = await fetch(`${SERVER_URL}/api/updateCourseById/${selectedCourseId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(newCourse),
      });

      if (!response.ok) {
        throw new Error('Failed to create course');
      }

      const updatedCourse = await response.json();
      setCourses(courses.map(course => course.courseId === updatedCourse.courseId ? updatedCourse : course));
      setSuccessMessage('Course updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      closeEditModal();
    } catch (error) {
      console.error('Error creating course:', error.message);
    }
  };
  
  const filteredCourses = courses.filter((course)=>{
    if(searchInput === "") return course;
    
    return course.courseName.toLowerCase().includes(searchInput.toLowerCase());

  })

  const groupedCategory = filteredCourses.reduce((acc, course) => {
    if (!acc[course.courseCategory]) {
      acc[course.courseCategory] = [];
    }
    acc[course.courseCategory].push(course);
    return acc;
  }
    , {});


  return (
    <div className="flex h-screen">
      <Sidebar user={user} />
      <div className="flex-1 p-4 overflow-auto mt-14 md:mx-2">
     
      <div className="flex items-center m-2 justify-between mb-4 md:mb-6">
  <div className='mx-4'>
    <h1 className="text-2xl font-semibold text-gray-800">Courses</h1>
  </div>
  
  <div className="flex items-center gap-x-4">
    <div className="relative w-full md:w-1/2">
      <input
        type="text"
        placeholder="Search Courses"
        value={searchInput}
        onChange={handleSearch}
        className="shadow appearance-none border rounded w-full py-2 pl-10 pr-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
      />
      <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgba(171,168,255,255)]">{searchIcon}</span>
    </div>
    
    {user.isAdmin && (
      <button onClick={openModal} className="ml-4">
        <Button className="bg-[rgba(142,75,221,255)] hover:bg-blue-700 text-white font-bold py-2 px-6 rounded">
          Create Course
        </Button>
      </button>
    )}
  </div>
</div>



  
        {successMessage && (
          <div className="bg-green-500 text-white px-4 py-2 rounded mb-4">
            {successMessage}
          </div>
        )}
  
        {
          isLoading ? (<LoadingSpinner />) : (
            <div>
         {Object.entries(groupedCategory).map(([category, courses]) => (
            <div key={category} className="bg-[rgba(245,246,250,255)] m-4 p-4 rounded-xl shadow-md">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">{category}</h2>
              <div className="overflow-auto max-h-72 grid grid-cols-1 sm:grid-cols-3 gap-4">
                {courses.map(course => (
                  <div key={course.id} className="bg-white text-gray-800 rounded-lg flex flex-col">
                    <div className="relative rounded-t-lg p-2 mb-2 bg-gray-200 flex items-center">
                      <h3 className="text-sm font-semibold flex">{course.courseName} </h3>
                      <span onClick={() => openEditModal(course.courseId)} className='absolute right-7 top-1/2 transform -translate-y-1/2 cursor-pointer inline-flex text-slate-500'>{pencilIcon}</span>
                    </div>
                    <div className="px-4 flex flex-col flex-grow">
                      <p className="text-gray-600 mb-4 text-xs">{course.courseDescription}</p>
                      <div className="mt-auto flex justify-end">
                        <Link  to={`/createcourses/${course.courseId}`}>
                          <Button className="bg-white text-xs text-purple-700 border-2 m-1 py-1 px-3.5 rounded-lg">
                            Edit Course
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          </div>
          )}
  
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Create New Course</h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="courseName" className="block text-sm font-medium text-gray-700">
                      Course Name
                    </label>
                    <input
                      type="text"
                      name="courseName"
                      value={newCourse.courseName}
                      onChange={handleInputChange}
                      placeholder="Enter Course Name"
                      className="shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="courseCategory" className="block text-sm font-medium text-gray-700">
                      Course Category
                    </label>
                    <select
                      name="courseCategory"
                      value={newCourse.courseCategory}
                      onChange={handleInputChange}
                      className="shadow appearance-none border rounded w-full py-2 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                      required
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option  key={category.id} value={category.id}>{category}</option>

                      ))}
                    </select>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="courseDescription" className="block text-sm font-medium text-gray-700">
                      Course Description
                    </label>
                    <textarea
                      name="courseDescription"
                      value={newCourse.courseDescription}
                      onChange={handleInputChange}
                      placeholder="Enter Course Description"
                      className="shadow appearance-none border rounded w-full py-2 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                      rows="4"
                      required
                    />
                  </div>
                  <div className="mt-6 flex justify-end">
                    <Button type="button" className="bg-gray-200 text-gray-700 mr-2" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button type="submit" className="bg-blue-600 text-white">
                      Create
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {isEditModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Edit Course</h3>
                <form onSubmit={handleSubmit2}>
                  <div>
                    <label htmlFor="courseName" className="block text-sm font-medium text-gray-700">
                      Course Name
                    </label>
                    <input
                      type="text"
                      name="courseName"
                      value={newCourse.courseName}
                      onChange={handleInputChange}
                      placeholder="Enter Course Name"
                      className="shadow appearance-none border rounded w-full py-2.5 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="courseCategory" className="block text-sm font-medium text-gray-700">
                      Course Category
                    </label>
                    <select
                      name="courseCategory"
                      value={newCourse.courseCategory}
                      onChange={handleInputChange}
                      className="shadow appearance-none border rounded w-full py-2 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                      required
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="courseDescription" className="block text-sm font-medium text-gray-700">
                      Course Description
                    </label>
                    <textarea
                      name="courseDescription"
                      value={newCourse.courseDescription}
                      onChange={handleInputChange}
                      placeholder="Enter Course Description"
                      className="shadow appearance-none border rounded w-full py-2 px-4 bg-violet-100 text-black leading-tight focus:outline-none focus:shadow-outline placeholder:text-[rgba(171,168,255,255)] text-sm"
                      rows="4"
                      required
                    />
                  </div>
                  <div className="mt-6 flex justify-end">
                    <Button type="button" className="bg-gray-200 text-gray-700 mr-2" onClick={closeEditModal}>
                      Cancel
                    </Button>
                    <Button type="submit" className="bg-blue-600 text-white">
                      Confirm
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
     
    </div>
 
  );
}
