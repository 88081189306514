   import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import SERVER_URL from './utils';
import LoadingSpinner from './LoadingSpinner';

function PublicRoute() {
  const [isVerified, setIsVerified] = useState(null);
  const authToken = Cookies.get('x-auth-cookie') ?? localStorage.getItem('token');
  console.log(authToken);

  useEffect(() => {
    const verifyToken = async () => {
      if (authToken) {
        try {
          const response = await axios.get(`${SERVER_URL}/api/auth/verifytoken`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
          });
          setIsVerified(response.data.verified);
        } catch (error) {
          console.log('Error verifying token:', error);
          setIsVerified(false);
        }
      } else {
        setIsVerified(false);
      }
    };

    verifyToken();
  }, [authToken]);

  if (isVerified === null) {
    return <LoadingSpinner />;
  }

  return isVerified ? <Navigate to="/documents" /> : <Outlet />;
}

export default PublicRoute;
