// SidebarData.js
import React from 'react';

import {
  dashboardIcon,
  settingIcon,
  policiesIcon,
  documentIcon,
  courseIcon,
  membershipIcon,
  insightIcon
} from '../Icons';

const sidebarData = [
  // {
  //   to: '/dashboard',
  //   icon: dashboardIcon,
  //   text: 'Dashboard',
  // },
  {
    to: '/documents',
    icon: documentIcon,
    text: 'My Documents',
  },
  // {
  //   to: '/insights',
  //   icon: insightIcon,
  //   text: 'Insights',
  // },
  {
    to: '/policies',
    icon: policiesIcon,
    text: 'Policies',
  },
  {
    to: '/courses',
    icon: courseIcon,
    text: 'Courses',
  },
  // {
  //   to: '/membership',
  //   icon: membershipIcon,
  //   text: 'Membership',
  // },
  {
    to: '/settings',
    icon: settingIcon,
    text: 'Settings',
  }
];

export default sidebarData;
