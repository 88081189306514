import React, { useState } from 'react';

const Button = ({ children, onClick = () => {}, className }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseDown = () => {
    setIsClicked(true);
  };

  const handleMouseUp = () => {
    setIsClicked(false);
  };

  const handleClick = (e) => {
    onClick(e);
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200); 
  };

  return (
    <button
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className={`bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] hover:from-white hover:to-white hover:text-[rgba(133,65,222,255)]  hover:shadow-xl border-2 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out ${isClicked ? 'transform scale-95' : ''} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
