import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/AdviceBytesLogo.png';
import saly from '../images/Saly.png';

const VerificationPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // Navigate to the login page after 8 seconds
            navigate('/login');
        }, 8000);

        // Cleanup the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [navigate]);

    return (
        <div className="flex justify-center items-center h-screen">
            {/* Left side */}
            <div className="w-full md:w-2/3 md:p-10 relative text-center">
                {/* Logo space */}
                <div className="mb-8">
                    {/* Logo component here */}
                    <img src={logo} alt="logo" className="w-20 h-20" />
                </div>

                {/* Heading and paragraph */}
                <div className="items-center justify-center mb-56">
                    <h1 className="text-2xl md:text-4xl font-bold text-black pb-3">A link has been sent to your email</h1>
                </div>
            </div>

            {/* Vector image */}
            <div className="w-1/2 md:w-1/3 lg:w-1/4">
                <img src={saly} alt="saly" className="w-full h-auto" />
                {/* Vector image component */}
            </div>
        </div>
    );
};

export default VerificationPage;
