import React from 'react';
import { ClipLoader,BeatLoader } from 'react-spinners';

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      {/* <ClipLoader size={50} color={"#36d7b7"} /> */}
      <BeatLoader size={20} color={"#8E4BDD"} />
    </div>
  );
};


export default LoadingSpinner;
