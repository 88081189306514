// src/components/AdminRoute.js
import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import NotFound from './notFound';
import SERVER_URL from './utils';

function AdminRoute() {
  const [isAdmin, setIsAdmin] = useState(null);
  const authToken = Cookies.get('x-auth-cookie') ?? localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAdmin = async () => {
      if (authToken) {
        try {
          const response = await axios.get(`${SERVER_URL}/api/auth/userinfo`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
          });
        //   console.log('isAdmin:', response.data.isAdmin);
          setIsAdmin(response.data.isAdmin);
        } catch (error) {
          console.log('Error:', error);
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    };

    verifyAdmin();
  }, [authToken]);

  if (isAdmin === null) {
    return <LoadingSpinner />;
  }

  return isAdmin ? <Outlet /> : <NotFound />;
}

export default AdminRoute;
