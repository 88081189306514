import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar';
import { Link } from 'react-router-dom';
import { downloadIcon, editIcon } from '../Icons';
import SERVER_URL from '../components/utils';
import LoadingSpinner from '../components/LoadingSpinner';
import jsPDF from 'jspdf';
import Cookies from 'js-cookie';

const authToken = localStorage.getItem('token') ?? Cookies.get('x-auth-cookie');

const DocumentCard = ({ row, changeDateFormat, onDownload }) => (
  <div className="bg-slate-100 shadow-md rounded-lg p-4 mb-4">
    <div className="flex justify-between items-center">
      <div>
        <p className="text-sm py-1 sm:space-x-5 space-x-1 text-gray-800">
          <strong>Policy Name:</strong>
          <span className="">{row.templateName}</span>
        </p>
        <p className="text-sm py-1 sm:space-x-8 space-x-1 text-gray-800">
          <strong>Created on:</strong>
          <span className="">{changeDateFormat(row.createdAt)}</span>
        </p>
        <p className="text-sm py-1 sm:space-x-7 space-x-1 text-gray-800">
          <strong>Updated on:</strong>
          <span className="">{changeDateFormat(row.updatedAt)}</span>
        </p>
      </div>
      <div className="flex flex-col mobile:flex-row justify-between items-center mobile:space-x-4">
        <Link to={`/customerform/${row.templateId}`}>
          <button className="mobile:w-28 xs:w-10 mid-large:w-12 w-16 text-xs sm:text-base bg-white text-[rgba(133,65,222,255)] py-2 px-1 rounded-lg flex flex-col items-center justify-center mt-2 md:mt-0 sm:px-6 sm:py-3">
            {editIcon}
            <span className="text-slate-500">Edit</span>
          </button>
        </Link>
        <button
          onClick={() => onDownload(row)}
          className="mobile:w-28 mid-large:w-20 xs:w-16 w-16 bg-white text-xs sm:text-base text-[rgba(133,65,222,255)] py-2 px-1 rounded-lg flex flex-col items-center justify-center mt-2 md:mt-0 sm:px-6 sm:py-3"
        >
          {downloadIcon}
          <span className="text-slate-500">Download</span>
        </button>
      </div>
    </div>
  </div>
);

const DocumentRow = ({ row, changeDateFormat, onDownload }) => (
  <tr className="bg-white border-b hover:bg-gray-100 text-xs">
    <th scope="row" className="px-2 py-1 sm:px-6 sm:py-4 font-medium text-gray-900 whitespace-nowrap">
      {row.category}
    </th>
    <td className="px-2 py-1 sm:px-6 sm:py-4 whitespace-nowrap">{row.templateName}</td>
    <td className="px-2 py-1 sm:px-6 sm:py-4 whitespace-nowrap">{changeDateFormat(row.createdAt)}</td>
    <td className="px-2 py-1 sm:px-6 sm:py-4 whitespace-nowrap">{changeDateFormat(row.updatedAt)}</td>
    <td className="px-2 py-1 sm:px-6 sm:py-4 text-[rgba(133,65,222,255)] hover:underline cursor-pointer">
      <Link to={`/customerform/${row.templateId}`}>Edit</Link>
    </td>
    <td className="px-2 py-1 sm:px-6 sm:py-4">
      <button onClick={() => onDownload(row)} className="font-medium text-[rgba(133,65,222,255)] hover:underline">
        {downloadIcon}
      </button>
    </td>
  </tr>
);

const changeDateFormat = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const downloadPdf = async (row) => {
  const response = await fetch(`${SERVER_URL}/api/generatePDF`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ templateId: row.templateId }),
  });

  const result = await response.json();
  const { templateName, htmlContent } = result;

  const doc = new jsPDF();
  doc.html(htmlContent, {
    callback: function (doc) {
      doc.save(`${templateName}.pdf`);
    },
    x: 10,
    y: 10,
    width: 180,
    windowWidth: 800,
  });
};

export default function Documents() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const getPolicy = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getUserForm`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) throw new Error('No policy found');
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPolicy();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const groupedData = data.reduce((acc, document) => {
    if (!acc[document.category]) {
      acc[document.category] = [];
    }
    acc[document.category].push(document);
    return acc;
  }, {});

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 mt-14 p-6 h-full overflow-auto">
        <div className="md:mx-2">
          <div className="flex items-center justify-between md:justify-start mb-4 md:mb-6">
            <h2 className="text-3xl font-normal text-mobile-color md:text-gray-800 whitespace-nowrap">
              My Documents
            </h2>
          </div>

          {/* component 1 start */}
          <div className="block md:hidden">
            <div className="overflow-y-auto h-full rounded-lg shadow-xl bg-white p-4">
              {loading ? (
                <div className="p-4 text-center">
                  <LoadingSpinner />
                </div>
              ) : error ? (
                <div className="p-4 text-center text-gray-400">
                  {error}
                </div>
              ) : Object.keys(groupedData).length > 0 ? (
                Object.keys(groupedData).map((category, index) => (
                  <div key={index} className="mb-8">
                    <h3 className="text-xl font-bold mb-4 text-gray-400">{category}</h3>
                    {groupedData[category].map((row, index) => (
                      <DocumentCard key={index} row={row} changeDateFormat={changeDateFormat} onDownload={downloadPdf} />
                    ))}
                  </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-700">
                  No documents found.
                </div>
              )}
            </div>
          </div>
          {/* component 1 end */}

          {/* component 2 start */}
          <div className="hidden md:block">
            <div className="overflow-y-auto max-h-96">
              <div className="relative overflow-auto max-h-96 shadow-md rounded-lg">
                <table className="w-full text-sm text-left text-gray-800">
                  <thead className="text-xs text-gray-700 bg-gray-200">
                    <tr>
                      <th scope="col" className="px-2 py-1 sm:px-6 sm:py-3">Category</th>
                      <th scope="col" className="px-2 py-1 sm:px-6 sm:py-3 whitespace-nowrap">Policy Name</th>
                      <th scope="col" className="px-2 py-1 sm:px-6 sm:py-3 whitespace-nowrap">Created on</th>
                      <th scope="col" className="px-2 py-1 sm:px-6 sm:py-3 whitespace-nowrap">Updated on</th>
                      <th scope="col" className="px-2 py-1 sm:px-6 sm:py-3"></th>
                      <th scope="col" className="px-2 py-1 sm:px-6 sm:py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6" className="p-4 text-center text-gray-700">
                          <LoadingSpinner />
                        </td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="6" className="p-4 text-center text-gray-700">
                          {error}
                        </td>
                      </tr>
                    ) : data.length > 0 ? (
                      data.map((row, index) => (
                        <DocumentRow key={index} row={row} changeDateFormat={changeDateFormat} onDownload={downloadPdf} />
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="p-4 text-center text-gray-700">
                          No documents found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        

        </div>
      </div>
    </div>
  );
}


