import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/AdviceBytesLogo.png';


export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, [location.pathname, navigate]);

  const handleButtonClick = (button) => {
    navigate(`/${button}`);
  };

  return (
    <div>
      <nav className="bg-white shadow">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center">
              <img
                src={logo}
                alt="AdviceBytes Logo"
                className="h-8 w-auto sm:h-10 cursor-pointer"
                onClick={() => navigate('/')}
              />
              <h1
                className="text-xl lg:text-2xl font-bold ml-2 cursor-pointer"
                onClick={() => navigate('/')}
              >
                AdviceBytes
              </h1>
            </div>
            <div className="hidden md:flex space-x-4 items-center">
              <button
                onClick={() => handleButtonClick('login')}
                className={`text-sm px-4 py-2 rounded transition duration-300 ${
                  location.pathname === '/login' ? 'bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] text-white' : ''
                } hover:bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)]`}
              >
                LOGIN
              </button>
              <button
                onClick={() => handleButtonClick('signup')}
                className={`text-sm px-4 py-2 rounded transition duration-300 ${
                  location.pathname === '/signup' ? 'bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] text-white' : ''
                } hover:bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)]`}
              >
                SIGNUP
              </button>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  {isMenuOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={() => handleButtonClick('login')}
              className={`block text-base px-4 py-2 rounded transition duration-300 w-full text-left ${
                location.pathname === '/login' ? 'bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] text-white' : ''
              } hover:bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)]`}
            >
              LOGIN
            </button>
            <button
              onClick={() => handleButtonClick('signup')}
              className={`block text-base px-4 py-2 rounded transition duration-300 w-full text-left ${
                location.pathname === '/signup' ? 'bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] text-white' : ''
              } hover:bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)]`}
            >
              SIGNUP
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}
