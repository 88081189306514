import Sidebar from "../components/sidebar";
import React, { useState, useEffect, useContext } from 'react';
import SERVER_URL from '../components/utils';
import { useParams } from 'react-router-dom';
import { CustomerFormContext } from '../contexts/CustomerFormContext';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from "../components/button";

export default function CustomerForm() {
  const authToken = localStorage.getItem('token');
  const { id } = useParams();
  const { userFormData, setUserFormData } = useContext(CustomerFormContext);
  const [template, setTemplate] = useState(null);
  const [validationError, setValidationError] = useState({});
  const [previewMode, setPreviewMode] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const getTemplate = async (templateId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getTemplateById/${templateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        console.error('Failed to fetch template');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };

  const getUserFormByTemplateId = async (templateId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/getUserFormByTemplateId/${templateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        console.error('Failed to fetch user form');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const templateData = await getTemplate(id);
      setTemplate(templateData);

      const userFormData = await getUserFormByTemplateId(id);
      if (userFormData) {
        setUserFormData(userFormData);
        localStorage.setItem('customerFormData', JSON.stringify(userFormData));
      } else {
        setUserFormData({
          templateId: templateData.templateId,
          templateName: templateData.templateName,
          customerPolicydata: '',
          category: templateData.category,
          userTag: templateData.questionnaire.map(q => ({ tagName: q.tagName, answer: '' }))
        });
      }
    };

    fetchData();
  }, [id, setUserFormData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserFormData((prevData) => ({
      ...prevData,
      userTag: prevData.userTag.map(tag =>
        tag.tagName === name ? { ...tag, answer: type === 'checkbox' ? checked : value } : tag
      )
    }));

    localStorage.setItem('customerFormData', JSON.stringify(userFormData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validationAnswer();
    if (Object.keys(errors).length > 0) {
      setValidationError(errors);
      return;
    }

    const customerPolicyData = replaceTagNames(template.data, userFormData.userTag);

    try {
      const response = await fetch(`${SERVER_URL}/api/createUserForm/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ...userFormData, 
          customerPolicyData: customerPolicyData,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Form data submitted:', data);
        setValidationError({});
        localStorage.setItem('customerFormData', JSON.stringify(userFormData));
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/documents");
        }, 3000);
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Server error:', error);
    }
  };

  const replaceTagNames = (template, userTags) => {
    let output = template;
    userTags.forEach(tag => {
      const regex = new RegExp(`{"${tag.tagName}"}`, 'g');
      output = output.replace(regex, tag.answer);
    });
    return output;
  };

  const validationAnswer = () => {
    const error = {};
    template.questionnaire.forEach((item) => {
      const userTag = userFormData.userTag.find(tag => tag.tagName === item.tagName);
      if (item.required && !userTag.answer) {
        error[item.tagName] = `${item.question} is required.`;
      } else if (item.dataType === 'Date' && userTag.answer) {
        const date = new Date(userTag.answer);
        if (isNaN(date.getTime())) {
          error[item.tagName] = `${item.question} must be a valid date.`;
        }
      } else if (item.dataType === 'Number' && userTag.answer) {
        if (isNaN(userTag.answer)) {
          error[item.tagName] = `${item.question} must be a number.`;
        }
      }
    });
    return error;
  };

  const handlePreview = () => {
    const errors = validationAnswer();
    if (Object.keys(errors).length > 0) {
      setValidationError(errors);
    } else {
      setPreviewMode(!previewMode);
    }
  };

  if (!template) {
    return <div>
      <LoadingSpinner />
     
    </div>;
  }

  const { questionnaire } = template;

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 mt-16 p-4 overflow-auto">
        <div className="bg-gray-200 shadow-xl p-4 flex items-center justify-center rounded-t-lg">
          <h2 className="text-xl font-semibold">{template.templateName}</h2>
        </div>
        <div className="mt-4 bg-white shadow-lg p-4 max-h-fit rounded-lg">
          {previewMode && (
            <Button
              type="button"
              onClick={() => setPreviewMode(!previewMode)}
              className="px-4 py-2   bg-mobile-color text-white rounded-lg shadow-md float-right"
            >
              Edit Policy
            </Button>
          )}

          <p className="text-gray-600 mb-2">Category: {template.category}</p>
          
          {previewMode ? (
            <div className="mt-7 bg-gray-100 p-4 rounded-md shadow-md">
              <h3 className="text-lg font-semibold">Preview</h3>
              <div dangerouslySetInnerHTML={{ __html: replaceTagNames(template.data, userFormData.userTag) }} />
              <Button
                onClick={handleSubmit}
                type="submit"
                className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg shadow-md"
              >
                Complete
              </Button>
            </div>
          ) : (
            <form className="flex flex-col space-y-4">
              <div className="border p-4 rounded-md shadow-md">
                <div className="mt-4 space-y-4">
                  <ul>
                    {questionnaire.map((item) => (
                      <li key={item.tagName} className="my-2">
                        {item.question} {item.required && <span style={{ color: 'red' }}>*</span>}
                        {item.preDefined ? (
                          <select
                            name={item.tagName}
                            value={userFormData.userTag.find(tag => tag.tagName === item.tagName)?.answer || ''}
                            onChange={handleChange}
                            className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          >
                            <option value="">Select an option</option>
                            {item.preDefinedValues.map((option, index) => (
                              <option key={index} value={option}>{option}</option>
                            ))}
                          </select>
                        ) : item.dataType === 'String' ? (
                          <input
                            type="text"
                            name={item.tagName}
                            value={userFormData.userTag.find(tag => tag.tagName === item.tagName)?.answer || ''}
                            onChange={handleChange}
                            className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        ) : item.dataType === 'Number' ? (
                          <input
                            type="number"
                            name={item.tagName}
                            value={userFormData.userTag.find(tag => tag.tagName === item.tagName)?.answer || ''}
                            onChange={handleChange}
                            className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        ) : item.dataType === 'Date' ? (
                          <input
                            type="date"
                            name={item.tagName}
                            value={userFormData.userTag.find(tag => tag.tagName === item.tagName)?.answer || ''}
                            onChange={handleChange}
                            className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name={item.tagName}
                            checked={userFormData.userTag.find(tag => tag.tagName === item.tagName)?.answer || false}
                            onChange={handleChange}
                            className="my-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        )}
                        {validationError[item.tagName] && (
                          <span style={{ color: 'red' }}>{validationError[item.tagName]}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex justify-center mt-4">
              <Button
                onClick={handlePreview}
                type="button"
                className=" flex justify-center items-center w-48 px-4 py-2 bg-mobile-color text-white rounded-lg shadow-md"
              >
                Preview Policy
              </Button>
              </div>
            </form>
          )}
        </div>
      </div>

      {showPopup  && (
        <div className="fixed z-50 left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded-md shadow-md">
          Your policy is saved in My Documents.
        </div>
      )}
    </div>
  );
}
