import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar';
import {userMan,arrowdown,arrowUp, arrowRight, pencilIcon} from '../Icons';
import SERVER_URL from '../components/utils';
import axios from 'axios';
import Cookies from 'js-cookie';

import LoadingSpinner from '../components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button';
const UserManagementContent = () => {
  const navigate = useNavigate();

  const [showPasswordFields, setShowPasswordFields] = useState(true);
  const [showUserFields, setShowUserFields] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const ConfirmModal = ({ showModal, onClose, onConfirm, title, message }) => {
    if (!showModal) return null;
  
    return (
      <div className="fixed inset-0 flex items-center justify-center ">
        <div className="bg-white p-6 rounded-md shadow-2xl border-2 ">
          <h2 className="text-lg font-bold mb-4">{title}</h2>
          <p className="mb-4">{message}</p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="bg-gray-100 text-[rgba(142,75,221,255)] hover:bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] font-bold hover:text-white px-8 py-1.5  hover:shadow-xl border-2 rounded-sm mr-2"
            >
              No
            </button>
            <Button
              onClick={onConfirm}
              className=" px-8 "
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    );
  };
  

 const toggleUserFields = () => {
    setShowUserFields(!showUserFields);
  };


  const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user);


  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [profileData, setProfileData] = useState({
    profilePage: false,
    avatarProfile: '',
  });
  const authToken = localStorage.getItem('token');

  const PasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${SERVER_URL}/api/auth/forgotpassword`, {
        email: user.email,
      });

      if (response.data.success) {
        navigate("/verificationpage");
      } else {

        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error.message);


    }
  };

  const DeactivateAccount = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${SERVER_URL}/api/auth/deactivateaccount`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      
      });
        const data = await response.json();
      if (data.success) {
        localStorage.clear();
        Cookies.remove('x-auth-cookie');

        navigate("/login");
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const toggleProfilePage = () => {
    setProfileData({ ...profileData, profilePage: !profileData.profilePage });
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateImage = async () => {
    setLoading(true);
    if (!selectedFile) return;
    const base64 = await convertToBase64(selectedFile);

    try {
      const response = await axios.put(
        `${SERVER_URL}/api/auth/updateimage`,
        { avatarUrl: base64 },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const updatedUser = response.data;
      setProfileData({ profilePage: false, avatarProfile: updatedUser });
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error('Update image error:', error.message);
    }
    setLoading(false);
    window.location.reload();
  };


  const handleFileSelection = (e) => {
    setSelectedFile(e.target.files[0]);

  };
  const handleSubmit = (e) => {
    e.preventDefault();
    updateImage();
  };
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);




  return (
    <div className="p-2 md:px-4 md:pb-8 container mb-4 md:ml-0 md:items-start h-fit">
      <div className="mb-2 w-full">
        <h1 className="text-lg font-bold text-center md:text-start">User Management</h1>
      </div>

      <div className="mt-3 w-full">
        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-2 items-center md:items-center">
          <h2 className="font-normal text-mobile-colorm text-center md:text-left">Profile</h2>

          <div className="flex flex-col md:flex-row gap-2 text-xs items-center md:items-start">
            <p className=""> <img
                src={user.avatarUrl}
                alt="avatar"
                className="h-16 w-16 md:h-12 md:w-12 rounded-full"
              /></p>
            <div onClick={toggleProfilePage} className="  text-slate-500 hover:cursor-pointer">
              {pencilIcon}
              Change 
            </div>
          </div>
        </div>

        {profileData.profilePage && (
          <form onSubmit={handleSubmit} className="mt-4 w-full flex flex-col items-center md:items-start">
            <input
              type="file"
              className="text-xs"
              id="avatar"
              name="avatar"
              accept="image/*"
              onChange={handleFileSelection}
            />
            {loading && <LoadingSpinner />}
            <button
              type="submit"
              className={`p-2 mt-2 text-white rounded-lg ${selectedFile ? 'bg-gradient-to-r from-[rgba(187,129,210,255)] to-[rgba(133,65,222,255)] ' : 'bg-gray-400'}`}
              disabled={!selectedFile}
            >
              Update
            </button>

          </form>
        )}

        {showSuccessMessage && (
          <div className="bg-green-200 text-green-800 rounded-md p-2 mt-2 text-center">
            Image successfully uploaded!
          </div>
        )}
       

       <div onClick={toggleUserFields} className="ml-auto mt-2 text-white flex rounded-full h-5 w-5 items-center justify-center arrow-down cursor-pointer bg-purple-500 ">
    {showUserFields ? arrowUp : arrowdown}
  </div>
  

  {showUserFields && (
    
        <div className="flex flex-col gap-1  select-none items-center md:items-start">
        <h3 className="text-[rgba(180,180,189,255)]  text-center md:text-left">First Name</h3>
        
        
          <div className="bg-white w-64 font-normal p-1.5 text-sm rounded-md w-custom text-center md:text-left">{user.firstname}</div>
          <h3 className="text-[rgba(180,180,189,255)]  text-center md:text-left">Last Name</h3>
          <div className="bg-white w-64 font-normal p-1.5 text-sm rounded-md w-custom text-center md:text-left">{user.lastname}</div>
          <h3 className="text-[rgba(180,180,189,255)] text-center md:text-left">Email Address</h3>
          <div className="bg-white w-64 font-normal p-1.5 text-sm rounded-md w-custom text-center md:text-left">{user.email}</div>
        </div>
      )}


        <hr className="h-0.5 rounded-lg my-6 bg-gray-200 border-0 dark:bg-gray-700 w-full"></hr>

        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-40 items-center md:items-start w-full">
          <div className="text-center md:text-left">
            <h1>Password</h1>
            <h4 className="text-[rgba(180,180,189,255)]">You email address is {user.email}</h4>
          </div>

          <div onClick={togglePasswordFields} className="ml-auto mt-2 text-white flex rounded-full h-5 w-5 items-center justify-center arrow-down cursor-pointer bg-purple-500">
            {showPasswordFields ? arrowUp : arrowdown}
          </div>
        </div>

        {showPasswordFields && (
          <form className="mt-4 w-full flex flex-col items-center md:items-start" onSubmit={PasswordReset}>
            <Button
              className="bg-[rgba(142,75,221,255)]  text-sm text-white font-bold py-2 px-3 w-3/5 md:w-5/12 rounded-lg focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Update Password
            </Button>
          </form>
        )}

        <hr className="h-0.5 rounded-lg my-6 bg-gray-200 border-0 dark:bg-gray-700 w-full"></hr>

        <div className="flex flex-col  gap-y-4 md:gap-x-8 items-center md:items-start w-full">
          <div className="text-center md:text-left">
            <h1>Deactivate Account</h1>
            <h4 className="text-[rgba(180,180,189,255)]">If you no longer want to receive emails</h4>
          </div>

          <Button
            className=" text-sm w-3/5 md:w-5/12 text-white font-bold px-4 py-2 rounded-lg focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            Deactivate
          </Button>

          <ConfirmModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={DeactivateAccount}
        title="Deactivate Account"
        message="Are you sure you want to deactivate your account? "
      />
        </div>
      </div>
    </div>
  );

};

// const SubscriptionContent = () => {

//   return (
//     <div>
//       <div className=''>
//       <h1 className = "text-3xl font-bold">Subscriptions</h1>

//       <h2 className='mt-6'>CURRENT PLAN</h2>
//       <hr className="h-0.5 rounded-lg mt-2 mb-6 bg-gray-200 border-0 dark:bg-gray-700 "></hr>

//       <h1 className = "text-2xl font-bold">Secure Plus Plan</h1>
//       <h2 className='text-xl'>€250 per month</h2>

//      <div className='flex flex-row gap-4 mt-2'>
//             <div className="flex items-center justify-between mt-3">
//             <button
//             className="bg-[rgba(142,75,221,255)] hover:bg-blue-950 text-sm shadow-md  text-white  px-5 py-3.5 rounded focus:outline-none focus:shadow-outline "
//             type="submit"
//           >
//             Upgrade  Plan
//           </button>
//           </div>
//           <div className="flex items-center justify-between mt-3">

//           <button
//             className="bg-white text-sm  text-[rgba(142,75,221,255)] shadow-md  px-5 py-3.5   rounded focus:outline-none focus:shadow-outline border-x border-y border-[rgba(142,75,221,255)]"
//             type="submit"
//           >
//             Cancel Plan
//           </button>
//             </div>
//       </div>

//       <h3 className='mt-4 mb-6'> Your plan renews on October 1,2024</h3>

//       <div className='mb-6'>
//         <h2>PAYMENT METHOD</h2>
//         <hr className="h-0.5 rounded-lg mt-2 mb-6 bg-gray-200 border-0 dark:bg-gray-700 "></hr>
//         <div>Card detils
//           <h5 className='text-sm font-semibold mt-2'>+ Add payment method</h5>
//         </div>

//       </div>

//       <div>
//       <h2>BILLING HISTORY</h2>
//       <hr className="h-0.5 rounded-lg mt-2 mb-6 bg-gray-200 border-0 dark:bg-gray-700 "></hr>


// <div className="relative overflow-auto">
//     <table className="w-full text-sm text-left rtl:text-right ">

//         <tbody>
//             <tr className="  ">
//                 <th scope="row" className="px-1 py-2 font-medium  whitespace-nowrap ">
//                     Dec 1,2022 
//                 </th>
//                 <td className="px-6 py-2">
//                     €250
//                 </td>
//                 <td className="px-6 py-2">
//                 Secure Plan Plus
//                 </td>
//                 <td className="px-6 py-2">
//                 {arrowtopright}
//                 </td>

//             </tr>
//             <tr className="  ">
//                 <th scope="row" className="px-1 py-2 font-medium ">
//                     Jan 1,2023 
//                 </th>
//                 <td className="px-6 py-2">
//                     €250
//                 </td>
//                 <td className="px-6 py-2">
//                 Secure Plan Plus
//                 </td>
//                 <td className="px-6 py-2">
//                 {arrowtopright}
//                 </td>

//             </tr>
//             <tr className="=">
//                 <th scope="row" className="px-1 py-2 font-medium ">
//                     Feb 1,2023  
//                 </th>
//                 <td className="px-6 py-2">
//                 €250
//                 </td>
//                 <td className="px-6 py-2">
//                     Secure Plan Plus
//                 </td>
//                 <td className="px-6 py-2">
//                 {arrowtopright}
//                 </td>

//             </tr>
//         </tbody>
//     </table>
// </div>


//       </div>

//       </div>



//     </div>
//   );
// };

// const InvoiceContent = () => {
//   // For fetching data from the backend and then rendering it here
//   return (
//     <div>
//       <div className=''></div>
//       <h1 className = "text-2xl font-bold">Invoice </h1>

//     </div>
//   );
// };

export default function Settings() {
  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  useEffect(() => {
    setActiveSection('userManagement');
  }, []);



  return (
    <div className="flex flex-col md:flex-row h-screen  bg-white">
      <Sidebar />

      <div className="flex-1 flex flex-col sm:flex-row mt-14  bg-white">
        <div className="md:my-6 mt-6 md:w-1/2 mx-4 md:mx-2">
          <div className="flex items-center justify-between md:justify-start mb-4 md:mb-2">
            <h2 className="text-3xl font-normal ml-2 md:ml-6  text-mobile-color md:text-gray-800 whitespace-nowrap">
              Settings
            </h2>

          </div>




        <div className=" pb-2 md:m-2 gap-2 md:py-4  md:p-4  text-[rgba(180,180,189,255)] text-base ">

          <div className={`flex flex-row gap-x-5 bg-[rgba(245,246,250,255)] p-2.5 rounded-lg hover:text-[rgba(142,75,221,255)] cursor-pointer ${activeSection === 'userManagement' ? 'text-[rgba(142,75,221,255)] font-normal' : ''}`}
          onClick={() => handleSectionClick('userManagement')}
>
            {userMan} User Management <p className="ml-auto">
              <span className='block md:hidden' >{arrowdown}</span>
              <span className='hidden md:block'>{arrowRight}</span>
              </p>
          </div>
          
         
          
          <div
            className={`flex flex-row gap-x-5 hover:text-[rgba(142,75,221,255)] cursor-pointer ${activeSection === 'subscriptions' ? 'text-[rgba(142,75,221,255)] font-semibold' : ''}`}
            onClick={() => handleSectionClick('subscriptions')}
          >
            {/* {SubscriptionsLogo} Subscriptions <p className="ml-auto">{arrowRight}</p> */}
          </div>

            <div
              className={`flex flex-row gap-x-5 hover:text-[rgba(142,75,221,255)] cursor-pointer ${activeSection === 'invoice' ? 'text-[rgba(142,75,221,255)] font-semibold' : ''}`}
              onClick={() => handleSectionClick('invoice')}
            >
              {/* {invoiceLogo} Invoice <p className="ml-auto">{arrowRight}</p> */}
            </div>
          </div>
        </div>

        {/* Right Side Content */}
        <div className="flex-1 w-full md:mt-12  p-4 md:p-8">
          <div className="flex h-full overflow-auto shadow-xl rounded-xl bg-[rgba(245,246,250,255)]">
            {activeSection === 'userManagement' && <UserManagementContent />}
            {/* {activeSection === 'subscriptions' && <SubscriptionContent />} */}
            {/* {activeSection === 'invoice' && <InvoiceContent />} */}
          </div>
        </div>
      </div>
    </div>


  );




}

